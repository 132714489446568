import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest, { cookies } from "../../services/auth";



export const getPricings = createAsyncThunk(
  "get/pricing",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/payment/prices`,
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

