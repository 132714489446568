import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import Pexels from "../../../../assests/home5.jpg";
import Home1 from "../../../../assests/home1.jpg";
import Home2 from "../../../../assests/home4.jpg";
import Man from "../../../../assests/man.jpg";
import Syongrouppreview from "../../../../assests/syongrouppreview.png";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaign,
  getAllCampaign,
} from "../../../../store/Campaign/campaignActions";
import Bed from "../../../../assests/bed.svg";
import Bathroom from "../../../../assests/bathroom.svg";
import Garage from "../../../../assests/garage.svg";
import NoImagePlaceholder from "../../../../assests/noimage.jpg"; // Add this placeholder image
import { S3_FILE_STORAGE_URL } from "../../../../constants/portConstants";
import { capitalizeFirstLetter } from "../../../../helpers/functions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const images = [Pexels, Home1, Home2];

const data = [{ id: 1 }, { id: 2 }];

const Active = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [viewType, setViewType] = useState("list");
  const [allCampaign, setAllCampaign] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const profiles = useSelector((state) => state.profiles.profiles);

  const handleViewChange = () => {
    setViewType(viewType === "grid" ? "list" : "grid");
  };

  useEffect(() => {
    dispatch(getAllCampaign());
  }, [dispatch]);

  useEffect(() => {
    if (campaigns.length > 0) {
      const tmp = campaigns.filter(
        (c) =>
          c.status == "active"
      );

      setAllCampaign(tmp);
    }
  }, [campaigns]);

  return (
    <Grid item xs={12} md={12} mt={6} px={1}>
      <Box
        sx={{
          bgcolor: "#fff",
          paddingBottom: 3,
          paddingTop: 3,
          paddingX: 4,
          borderRadius: 1,
          width: "100%",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontWeight: 600,
            fontSize: "25px",
            mb: 3,
          }}
        >
          Your current Listings ({allCampaign?.length})
        </Typography>

        {/* Toggle Buttons */}
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <IconButton
            color={viewType === "list" ? "primary" : "default"}
            onClick={handleViewChange}
          >
            <ViewListIcon />
          </IconButton>
          <IconButton
            color={viewType === "grid" ? "primary" : "default"}
            onClick={handleViewChange}
          >
            <GridViewIcon />
          </IconButton>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {allCampaign.map((camp, index) => (
                <Grid key={index} item xs={viewType === "grid" ? 4 : 12}>
                  <Box
                    sx={{ position: "relative", mb: 4 }}
                  // onClick={() =>
                  //   navigate(`/property-details/${camp._id}`)
                  // }
                  >
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        zIndex: 1,
                      }}
                    >
                      <EditIcon
                        onClick={() => {
                          navigate(`/listings/${camp._id}`, {
                            state: { id: camp._id },
                          });
                        }}
                        sx={{
                          backgroundColor: "#ffff",
                          borderRadius: 1,
                          PointerEvent: "Visibility",
                        }}
                      />

                      <DeleteIcon
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteId(camp._id);
                        }}
                        sx={{
                          backgroundColor: "#ffff",
                          borderRadius: 1,
                          PointerEvent: "Visibility",
                        }}
                      />
                    </Stack>

                    <Stack
                      sx={{
                        backgroundColor: "#fff",
                        boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                        borderRadius: 2,
                        borderColor: "gray",
                        overflow: "hidden",
                      }}
                      onClick={() => navigate(`/property-details/${camp._id}`)}
                    >
                      <Stack
                        sx={{
                          backgroundColor: "#24003d",
                          height: viewType === "grid" ? 40 : 70,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          px={2}
                          fontSize={20}
                          color={"#fff"}
                          fontWeight={"bold"}
                        >
                          {capitalizeFirstLetter(camp.description.title)}
                        </Typography>
                      </Stack>

                      <Grid
                        container
                        spacing={2}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Grid item xs={viewType === "list" ? 6 : 12}>
                          <Stack>
                            {/* <img
                              style={{
                                width: "100%",
                                height: viewType === "grid" ? 170 : 300,
                                objectFit: "cover",
                                display: "block",
                              }}
                              src={
                                camp.media?.photo?.file
                                  ? `${S3_FILE_STORAGE_URL}/${camp.media.photo.file}`
                                  : NoImagePlaceholder
                              }
                              alt={""}
                            /> */}
                            <div>
                              {camp?.media?.photo?.file?.length > 0 ? (
                                <Carousel
                                  showArrows={true}
                                  showThumbs={false}
                                  infiniteLoop={true}
                                  autoPlay={true}
                                  dynamicHeight={true}
                                >
                                  {camp?.media?.photo?.file.map(
                                    (image, index) => (
                                      <div key={index}>
                                        <img
                                          src={`${S3_FILE_STORAGE_URL}/${image}`}
                                          alt={`Slide ${index}`}
                                          style={{
                                            width: "100%",
                                            height: viewType === "grid" ? "200px" : "300px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </div>
                                    )
                                  )}
                                </Carousel>
                              ) : (
                                <img
                                  src={NoImagePlaceholder}
                                  alt="No available images"
                                  style={{
                                    width: "100%",
                                    height: viewType === "grid" ? "200px" : "300px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container spacing={2}>
                            {viewType === "list" && (
                              <>
                                <Grid item xs={4}>
                                  <Stack direction={"row"} spacing={2} p={2}>
                                    <Stack
                                      direction={"row"}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      spacing={0.5}
                                    >
                                      <Typography
                                        sx={{ fontWeight: 600, fontSize: 14 }}
                                      >
                                        {camp?.details?.bathRoom}
                                      </Typography>
                                      <img
                                        style={{
                                          width: 15,
                                          height: 15,
                                          display: "block",
                                          filter: "brightness(0) invert(0)",
                                        }}
                                        src={Bathroom}
                                        alt=""
                                      />
                                    </Stack>
                                    <Stack
                                      direction={"row"}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      spacing={0.5}
                                    >
                                      <Typography
                                        sx={{ fontWeight: 600, fontSize: 14 }}
                                      >
                                        {camp?.details?.bedRoom}
                                      </Typography>
                                      <img
                                        style={{
                                          width: 15,
                                          height: 15,
                                          display: "block",
                                          filter: "brightness(0) invert(0)",
                                        }}
                                        src={Bed}
                                        alt=""
                                      />
                                    </Stack>
                                    <Stack
                                      direction={"row"}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      spacing={0.5}
                                    >
                                      <Typography
                                        sx={{ fontWeight: 600, fontSize: 14 }}
                                      >
                                        {camp?.details?.garage}
                                      </Typography>
                                      <img
                                        style={{
                                          width: 15,
                                          height: 15,
                                          display: "block",
                                          filter: "brightness(0) invert(0)",
                                        }}
                                        src={Garage}
                                        alt=""
                                      />
                                    </Stack>
                                  </Stack>
                                  <Stack spacing={3}>
                                    <Stack direction={"row"} spacing={0.5}>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: 14,
                                          color: "#0f2e5a",
                                        }}
                                      >
                                        House Area: {camp?.details?.houseArea}{" "}
                                        m²
                                      </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={0.5}>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: 14,
                                          color: "#0f2e5a",
                                        }}
                                      >
                                        Land Area: {camp?.details?.landArea} m²
                                      </Typography>
                                    </Stack>
                                    <Stack direction={"row"} spacing={0.5}>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: 14,
                                          color: "#0f2e5a",
                                        }}
                                      >
                                        LivingArea: {camp?.details?.livingArea}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={8}>
                                  <Stack
                                    p={2}
                                    spacing={2}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "start",
                                    }}
                                  >
                                    <Stack spacing={1}>
                                      <Typography
                                        fontWeight={700}
                                        sx={{ fontSize: 18 }}
                                      >
                                        {profiles?.firstName}{" "}
                                        {profiles?.lastName}
                                      </Typography>
                                      <Stack spacing={1} direction={"row"}>
                                        <Typography
                                          fontWeight={700}
                                          sx={{ fontSize: 14 }}
                                        >
                                          Mobile No :
                                        </Typography>
                                        <Typography
                                          fontWeight={600}
                                          sx={{ fontSize: 14 }}
                                        >
                                          {profiles?.mobileNumber}
                                        </Typography>
                                      </Stack>
                                      <Stack spacing={1} direction={"row"}>
                                        <Typography
                                          fontWeight={700}
                                          sx={{ fontSize: 14 }}
                                        >
                                          Email ID
                                        </Typography>
                                        <Typography
                                          fontWeight={600}
                                          sx={{ fontSize: 14 }}
                                        >
                                          : {profiles?.email}
                                        </Typography>
                                      </Stack>

                                      <Stack spacing={1} direction={"row"}>
                                        <Typography
                                          fontWeight={700}
                                          sx={{ fontSize: 14 }}
                                        >
                                          Name :
                                        </Typography>
                                        <Typography
                                          fontWeight={600}
                                          sx={{ fontSize: 14 }}
                                        >
                                          {
                                            profiles.realEstate
                                              ?.firstName
                                          }
                                          {profiles?.realEstate?.lastName}
                                        </Typography>
                                      </Stack>

                                      <Stack>
                                        <Typography
                                          fontWeight={700}
                                          sx={{ fontSize: 14 }}
                                        >
                                          Address :
                                        </Typography>
                                        <Typography
                                          fontWeight={600}
                                          sx={{ fontSize: 14 }}
                                        >
                                          {
                                            profiles?.realEstate?.address
                                              ?.fullAddress
                                          }
                                        </Typography>
                                      </Stack>
                                      <Stack spacing={1} direction={"row"}>
                                        <Typography
                                          fontWeight={700}
                                          sx={{ fontSize: 14 }}
                                        >
                                          Lic :
                                        </Typography>
                                        <Typography
                                          fontWeight={600}
                                          sx={{ fontSize: 14 }}
                                        >
                                          {
                                            profiles?.realEstate
                                              ?.licenseNumber
                                          }
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                  <Stack spacing={2}>
                                    {viewType === "grid" && (
                                      <>
                                        <Stack
                                          spacing={0.5}
                                          direction={"row"}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: 14,
                                            }}
                                          >
                                            {camp?.details?.bathRoom}
                                          </Typography>
                                          <img
                                            style={{
                                              width: 15,
                                              height: 15,
                                              display: "block",
                                              filter: "brightness(0) invert(0)",
                                            }}
                                            src={Bathroom}
                                            alt=""
                                          />
                                        </Stack>
                                        <Stack direction={"row"} spacing={0.5}>
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: 14,
                                            }}
                                          >
                                            {camp?.details?.bedRoom}
                                          </Typography>
                                          <img
                                            style={{
                                              width: 15,
                                              height: 15,
                                              display: "block",
                                              filter: "brightness(0) invert(0)",
                                            }}
                                            src={Bed}
                                            alt=""
                                          />
                                        </Stack>
                                        <Stack
                                          direction={"row"}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          spacing={0.5}
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: 14,
                                            }}
                                          >
                                            {camp?.details?.garage}
                                          </Typography>
                                          <img
                                            style={{
                                              width: 15,
                                              height: 15,
                                              display: "block",
                                              filter: "brightness(0) invert(0)",
                                            }}
                                            src={Garage}
                                            alt=""
                                          />
                                        </Stack>
                                      </>
                                    )}
                                  </Stack>


                                </Grid>
                                <Grid item xs={12}>
                                  <Stack
                                    width={"100%"}
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      fontWeight={400}
                                      sx={{
                                        fontSize: 18,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        color: "grey",
                                      }}
                                    >
                                      {camp?.description?.description}
                                    </Typography>

                                    <StarBorderIcon sx={{ fontSize: 30 }} />
                                  </Stack>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* </div>
                        ))}
                      </Carousel> */}

                      <Stack spacing={2} p={viewType === "grid" ? 2 : 0}>
                        {viewType === "grid" && (
                          <>
                            <Stack
                              direction={"row"}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                fontWeight={400}
                                sx={{
                                  fontSize: 18,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: "grey",
                                }}
                              >
                                {camp?.description?.description}
                              </Typography>

                              <StarBorderIcon sx={{ fontSize: 30 }} />
                            </Stack>

                            <Stack direction={"row"} spacing={2}>
                              <>
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    filter: "brightness(0) invert(0)",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: 14 }}
                                  >
                                    {camp?.details?.bathRoom}
                                  </Typography>
                                  <img
                                    style={{
                                      width: 15,
                                      height: 15,
                                      display: "block",
                                    }}
                                    src={Bathroom}
                                    alt=""
                                  />
                                </Stack>
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: 14 }}
                                  >
                                    {camp?.details?.bedRoom}
                                  </Typography>
                                  <img
                                    style={{
                                      width: 15,
                                      height: 15,
                                      display: "block",
                                      filter: "brightness(0) invert(0)",
                                    }}
                                    src={Bed}
                                    alt=""
                                  />
                                </Stack>
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    sx={{ fontWeight: 600, fontSize: 14 }}
                                  >
                                    {camp?.details?.garage}
                                  </Typography>
                                  <img
                                    style={{
                                      width: 15,
                                      height: 15,
                                      display: "block",
                                      filter: "brightness(0) invert(0)",
                                    }}
                                    src={Garage}
                                    alt=""
                                  />
                                </Stack>
                                <Divider orientation="vertical" flexItem />
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: 14,
                                      color: "#0f2e5a",
                                    }}
                                  >
                                    Living Area: {camp?.details?.livingArea}
                                  </Typography>
                                </Stack>
                              </>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                              <>
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: 14,
                                      color: "#0f2e5a",
                                    }}
                                  >
                                    House Area: {camp?.details?.houseArea} m²
                                  </Typography>
                                </Stack>
                                <Divider orientation="vertical" flexItem />
                                <Stack
                                  direction={"row"}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  spacing={0.5}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: 14,
                                      color: "#0f2e5a",
                                    }}
                                  >
                                    Land Area: {camp?.details?.landArea} m²
                                  </Typography>
                                </Stack>
                              </>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={deleteModal} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontWeight: 600 }}>Delete Confirmation</DialogTitle>
        {/* <Box position="absolute" top={0} right={0}>
          <IconButton>
            <CloseIcon
              sx={{ fontSize: 30 }}
              onClick={() => setDeleteModal(false)}
            />
          </IconButton>
        </Box> */}
        <Stack ml={3}>
          <Typography>Are you sure you want to delete the listing?</Typography>
        </Stack>
        <DialogActions sx={{ marginBottom: 2, marginRight: 2 }}>
          <Button
            disableElevation
            sx={{
              border: "1px solid lightgray",
              width: "fit-content",

              fontWeight: 600,
              letterSpacing: 0,
              color: "#000",
              bgcolor: "#fff !important",
              textTransform: "none",
              border: "2px solid #24003D !important",
              borderRadius: "8px",
            }}
            color="primary"
            variant="contained"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            sx={{
              border: "1px solid lightgray",
              width: "fit-content",

              fontWeight: 600,
              color: "#fff",
              bgcolor: "#24003D !important",
              border: "1px solid #24003D !important",
              textTransform: "none",
              borderRadius: "5px",
              px: 4,
              boxShadow:
                "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "none",
              },
            }}
            onClick={() => {
              dispatch(
                deleteCampaign({
                  _id: [deleteId],
                })
              );
              setDeleteModal(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Active;
