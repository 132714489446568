import axios from "axios";
import { BASE_URL } from "../../constants/portConstants";
import { Cookies } from "react-cookie";
import { notify } from "../../utlis/handler";
export const cookies = new Cookies();

axios.defaults.baseURL = BASE_URL;
const timeout = 40000;

let prevMessage = "";
export default async function apiRequest(message) {
  if (prevMessage !== message) {
    notify(message + prevMessage);
  }
  prevMessage = message;

  setTimeout(() => {
    prevMessage = "";
  }, 1000);
}
