import { configureStore } from "@reduxjs/toolkit";
import UserReducers from "./user/UserReducers";
import authReducers from "./auth/authReducers";
import { NODE_ENV } from "../constants/portConstants";
import propertyReducers from "./property/propertyReducers";
import notificationReducers from "./notification/notificationReducers";
import reportReducers from "./report/reportReducers";
import paymentReducers from "./payment/paymentReducers";
import portfolioHoldingReducers from "./portfolioHolding/portfolioHoldingReducers";
import profileReducers from "./profile/profileReducers";
import privacyReducers from "./privacy/PrivacyReducers";
import cashFlowReducers from "./cashFlow/cashFlowReducers";
import commonReducers from "./common/commonReducers";
import socketReducers from "./socket/socketReducers";
import socketMiddleware from "./socket/socketMiddleware";
import reminderReducers from "./reminder/reminderReducers";
import campaignReducers from "./Campaign/campaignReducers";
import leadReducers from "./lead/leadReducers";
import noteReducers from "./note/noteReducers";

export const store = configureStore({
  reducer: {
    user: UserReducers,
    auth: authReducers,
    properties: propertyReducers,
    notifications: notificationReducers,
    reminders: reminderReducers,
    reports: reportReducers,
    payments: paymentReducers,
    portfolios: portfolioHoldingReducers,
    profiles: profileReducers,
    privacy: privacyReducers,
    common: commonReducers,
    socket: socketReducers,
    cashFlow: cashFlowReducers,
    campaign: campaignReducers,
    lead:leadReducers,
    note:noteReducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(socketMiddleware),
  // devTools: NODE_ENV === "development",
});
