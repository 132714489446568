import * as yup from 'yup';

const ownershipErrorMessage = "Ownership is required and must be a number between 1 and 100.";
const nameErrorMessage = "This field is required and must be at most 30 characters.";
const requiredErrorMessage = "This field is required.";
const numberErrorMessage = "This field must be a number.";

export const refinanceCalculatorSchema = yup.object().shape({
  // newFrequency: yup.string().required(requiredErrorMessage),
  newLoanTerm: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
  newInterestRate: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
  newIntroTerm: yup.string().required(requiredErrorMessage),
  newIntroRate: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
  // frequency: yup.string().required(requiredErrorMessage),
  loanTerm: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
  interestRate: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
  introTerm: yup.string().required(requiredErrorMessage),
  introRate: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
  loanAmount: yup.number().required(requiredErrorMessage).typeError(numberErrorMessage),
});
