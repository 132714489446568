import * as React from "react";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+61 0000 000 000"
      definitions={{
        0: /[0-9]/,
      }}
      style={{
        border: "none",
        outline: "none",
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInputs({
  onChange = () => {},
  value = "",
  placeholder = "",
  ...reset
}) {
  return (
    <Stack direction="row" spacing={2}>
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <Input
          style={{ borderWidth: 1, paddingLeft: 15 }}
          value={value}
          onChange={onChange}
          name="textmask"
          id="formatted-australian-phone-input"
          inputComponent={TextMaskCustom}
          placeholder={placeholder}
          disableUnderline={true}
          sx={{
            border: "1.5px solid lightgray",
            padding: "3px",
            fontSize: "13px",
            height: "41px",
            borderRadius: 5,
            boxShadow: 3,
            ...reset.sx ? reset.sx : {}
          }}
        />
      </FormControl>
    </Stack>
  );
}
