import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(3),
    // outline: 'none',
    // width: '100%',
    // maxWidth: 400, // Adjust the maximum width as needed
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  heigth: "100%",
  width: "100%",
  boxShadow: 24,
  p: 4,
};

const FullWidthModal = ({ open, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      // sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          background: "#fff",
          width: "100%",
          height: "100%",
          zIndex: 9999,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        p={{ md: 20, xs: 1 }}
      >
        <Stack
          sx={{
            width: "100%",
            alignItems: "end",
            marginRight: "10px",
            position: "absolute",
            top: "10px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Stack>
        <Stack>
          <Typography
            variant="h1"
            align="center"
            gutterBottom
            fontWeight={600}
            fontSize={40}
            color={"#0f2e5a"}
          >
            Welcome! Are you a investor or partner?
          </Typography>
        </Stack>

        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item>
            <Button
               variant="outlined"
              color="primary"
              // startIcon={<AccountCircleIcon />}
              fullWidth
              sx={{
                borderColor: "#24003D",
                color: "#24003D",
                backgroundColor: "transparent", // Set initial background color
                width: "auto",
                fontSize: 20,
                fontWeight: "bold",
                transition: "all 0.3s ease", // Smooth transition for hover effects
                "&:hover": {
                  transform: "scale(1)", // Slight scaling on hover
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Enhanced shadow on hover
                  color: "white", // Change text color on hover
                  backgroundColor: "#24003D", // Change background color on hover
                },
              }}
              p={5}
              onClick={() => navigate("/login")}
            >
              Investor
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              // startIcon={<AccountCircleIcon />}
              fullWidth
              style={{ marginLeft: 8 }}
              sx={{
                borderColor: "#24003D",
                color: "#24003D",
                backgroundColor: "transparent", // Set initial background color
                width: "auto",
                fontSize: 20,
                fontWeight: "bold",
                transition: "all 0.3s ease", // Smooth transition for hover effects
                "&:hover": {
                  transform: "scale(1)", // Slight scaling on hover
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Enhanced shadow on hover
                  color: "white", // Change text color on hover
                  backgroundColor: "#24003D", // Change background color on hover
                },
              }}
              onClick={() => navigate("/partner/login")}
              // disabled={true}
            >
              Partner
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default FullWidthModal;
