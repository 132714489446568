import * as React from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Paper,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Checkbox,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormControlLabel,
  CircularProgress,
  Slider,
  Radio,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GOOGLE_API_KEY } from "../../../../constants/portConstants";
import Autocomplete from "react-google-autocomplete";
import RenderErrorMessage from "../../../../common/RenderErrorMessage";
import CurrencyInput from "../../../../common/CurrencyInput";
import Active from "../../Listings/MyListings/Active";
import CurrentListing from "../CurrentListing";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Home from "../../../../components/SidebarWithTopBar";
import PhoneNumberInput from "../../../../common/PhoneNumberInput";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import CommonSelect from "../../../../common/CommonSelect";
import { createLead } from "../../../../store/lead/leadActions";
import TextFieldComponent from "../../../../common/TextFieldComponent";
import apiRequest from "../../../../services/auth";
import Popover from "@mui/material/Popover";

import {
  getFilteredCampaigns,
  getGenieCampaigns,
  getSearchCampaigns,
} from "../../../../store/Campaign/campaignActions";
import { getProperties } from "../../../../store/property/propertyActions";
import PercentageInput from "../../../../common/PercentageInput";
import BedIcon from "@mui/icons-material/Bed";

import * as yup from "yup";
import { Validate } from "../../../../helpers/functions";
import KeyboardBackspaceIcon from "@mui/icons-material/ArrowBack";
import FavouriteListing from "../FavouriteListing";
import { notify } from "../../../../utlis/handler";

export const querySchema = yup.object().shape({
  notes: yup.string().required("Notes is required"),
  contactMethod: yup.string().required("Contact method is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  mobileNumber: yup
    .string()
    .required("Please enter a valid mobile number - 04XX XXX XXX")
    .test(
      "Check prefix",
      "Please enter a valid mobile number - 04XX XXX XXX ",
      (value) => {
        return (
          value &&
          value.startsWith("+61 04") &&
          value.replace(/\s+/g, "").length === 13
        );
      }
    )
    .test(
      "Check all zeros",
      "Please enter a valid mobile number - 04XX XXX XXX",
      (value) => {
        const numberPart = value.replace("+61 04", "").replace(/\s+/g, "");
        return !/^0+$/.test(numberPart);
      }
    ),
  lastName: yup
    .string()
    .max(30, "Last Name should not exceed 30 characters")
    .matches(/^[a-zA-Z ]+$/, "Please enter a valid last name")
    .required("Last Name is required"),
  firstName: yup
    .string()
    .max(30, "First Name should not exceed 30 characters")
    .matches(/^[a-zA-Z ]+$/, "Please enter a valid first name")
    .required("First Name is required"),
});
const data1 = [
  { name: "Bedrooms*", key: "bedRoom" },
  { name: "Bathrooms*", key: "bathRoom" },
  { name: "Garages*", key: "garage" },
];

const resultsColumnTitles = [
  { name: "Property Type", key: "" },
  { name: "Property Address", key: "" },
  { name: "Loan Value", key: "" },
  { name: "Product", key: "" },
  { name: "Loan Type", key: "" },
  { name: "Interest Type", key: "" },
  { name: "Interest Rate", key: "" },
  { name: "Current Repayment", key: "" },
  { name: "New Repayment", key: "" },
  { name: "Savings", key: "" },
];

const productsColumnTitles = [
  { name: "Property Type", key: "" },
  { name: "Property Address", key: "" },
  { name: "Loan Value", key: "" },
  { name: "Product", key: "" },
  { name: "Loan Type", key: "" },
  { name: "Interest Type", key: "" },
  { name: "Interest Rate", key: "" },
  { name: "", key: "" },
];

const propertiesColumnTitles = [
  { name: "Product", key: "" },
  { name: "Loan Type", key: "" },
  { name: "Interest Type", key: "" },
  { name: "Interest Rate % p.a", key: "" },
  { name: "Comparision Rate % p.a", key: "" },
  { name: "", key: "" },
];

const formatPrice = (value) => {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + "K";
  }
  return value;
};

const TownSquareSelection = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  let pathnames = location.pathname;
  const {
    PRODUCTS,
    LOAN_TYPES,
    INTEREST_TYPES,
    CONTACT_METHOD,
    PROPERTY_TYPES,
  } = useSelector((state) => state.common.data);
  const searchProducts = useSelector((state) => state.campaign.products);
  const allCampaigns = useSelector((state) => state.campaign.campaigns);
  const genieCampaigns = useSelector((state) => state.campaign.genieCampaigns);
  const properties = useSelector((state) => state.properties.properties);
  const profiles = useSelector((state) => state.profiles.profiles);

  const [sortBy, setSortBy] = useState("property.type");
  const [sortOrder, setSortOrder] = useState(1);
  const [ownerOccupied, setOwnerOccupied] = useState(false);
  const [investorLoans, setInvestorLoans] = useState(false);
  const [agent, setAgent] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [allProducts, setProducts] = useState([]);
  const [allProperties, setProperties] = useState([]);
  const [genieProducts, setGenieProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [data, setData] = useState({
    details: data1.reduce((acc, item) => ({ ...acc, [item.key]: 0 }), {}),
    price: {
      minPrice: "",
      maxPrice: "",
    },
    landArea: "",
  });
  const [lead, setLead] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    status: "new",
    contactMethod: "",
    notes: "",
  });
  const [activeTab, setActiveTab] = useState("");
  const [result, setResults] = useState(false);
  const [error, setError] = useState([]);
  const [currentListingData, setCurrentListingData] = useState(allCampaigns);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorElBath, setAnchorElBath] = React.useState(null);
  const [anchorElGarage, setAnchorElGarage] = React.useState(null);
  const [anchorElLand, setAnchorElLand] = React.useState(null);

  const [priceRange, setPriceRange] = useState([0, 10000000]);
  const [areaRange, setAreaRange] = useState([0, 10000]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleBathroomClick = (event) => {
    setAnchorElBath(event.currentTarget);
  };
  const handleGarageClick = (event) => {
    setAnchorElGarage(event.currentTarget);
  };
  const handleLandClick = (event) => {
    setAnchorElLand(event.currentTarget);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBathClose = () => {
    setAnchorElBath(null);
  };
  const handleGarageClose = () => {
    setAnchorElGarage(null);
  };
  const handleLandClose = () => {
    setAnchorElLand(null);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open = Boolean(anchorEl);
  const openBath = Boolean(anchorElBath);
  const openGarage = Boolean(anchorElGarage);
  const open1 = Boolean(anchorEl1);
  const openLand = Boolean(anchorElLand);

  const id = open ? "simple-popover" : undefined;

  const [ctx, setCtx] = useState("State");

  const [value, setValue] = useState([0, 6]);
  const [valueBath, setValueBath] = useState([0, 6]);
  const [valueGarage, setValueGarage] = useState([0, 6]);
  const [value1, setValue1] = useState(["$0"]);
  const marks = [
    { value: 0, label: "Any" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
  ];

  const [search, setSearch] = useState({
    address: "",
    bedRoomMin: 0,
    bedRoomMax: 6,
    bathRoomMin: 0,
    bathRoomMax: 6,
    garageMin: 0,
    garageMax: 6,
    landAreaMin: "",
    landAreaMax: "",
    minPrice: "",
    maxPrice: "",
    isFavourite: true,
  });

  const onSearch = async () => {
    try {
      setLoading(true);
      dispatch(getFilteredCampaigns(search)).then((response) => {
        if (response?.payload?.success) {
          setLoading(false);
          navigate("/investment-property/search");
        }
      });
    } catch (err) {
      console.error("Search request failed:", err);
      setLoading(false);
    }
  };
  const [address, setAddress] = useState({
    fullAddress: "",
    lat: "",
    lng: "",
    suburb: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    addressLine1: "",
  });

  const handlePlaceSelected = (place) => {
    if (!place?.geometry) return;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const parsedAddress = {};

    // Mapping the address components
    place.address_components?.forEach((component) => {
      const types = component.types;
      if (types.includes("locality")) {
        parsedAddress["suburb"] = component.long_name;
        parsedAddress["city"] = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        parsedAddress["state"] = component.short_name;
      } else if (types.includes("country")) {
        parsedAddress["country"] = component.long_name;
      } else if (types.includes("postal_code")) {
        parsedAddress["postalCode"] = component.short_name;
      } else if (types.includes("route") || types.includes("street_number")) {
        parsedAddress["addressLine1"] = parsedAddress["addressLine1"]
          ? `${parsedAddress["addressLine1"]} ${component.short_name}`
          : component.short_name;
      }
    });

    setAddress({
      ...parsedAddress,
      lat: `${lat}`,
      lng: `${lng}`,
      fullAddress: place.formatted_address,
    });
  };
  const handleIncrement = (key) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: prevData[key] + 1,
    }));
  };

  const handleDecrement = (key) => {
    setSearch((prevData) => ({
      ...prevData,
      [key]: prevData[key] > 0 ? prevData[key] - 1 : 0,
    }));
  };
  const handleAdd = async () => {
    let validationError = await Validate(querySchema, lead);
    if (validationError) {
      setError(validationError);
      return;
    } else {
      setError({
        path: null,
      });
    }
    if (!isChecked) {
      return;
    }
    try {
      dispatch(
        createLead({
          ...lead,
          campaign: campaignId,
        })
      ).then((response) => {
        if (response?.payload?.success) {
          setAgent(false);
          setTimeout(() => {
            setLead({
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              status: "new",
              contactMethod: "",
              notes: "",
            });
          }, 500);
        }
      });
    } catch (error) {
      console.error("Error occurred during validation:", error);
    }
  };
  const deleteRow = (index) => {
    const updatedRows = [...allProperties];
    updatedRows.splice(index, 1);
    setProperties(updatedRows);
  };
  const goBack = () => {
    window.history.back();
  };
  function calculateInterestOnlyRepayment(P, annualRate) {
    const A = 12;
    const r = annualRate / A / 100; // Convert annual rate to monthly and percentage to decimal
    const interestOnlyRepayment = P * r;
    return Math.floor(interestOnlyRepayment);
  }

  function calculateMonthlyRepayment(P, annualRate) {
    const A = 12;
    const r = annualRate / A / 100; // Convert annual rate to monthly and percentage to decimal
    const n = 30 * 12; // Convert loan term in years to months
    const M = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    return Math.floor(M);
  }

  function calculateCurrentRepayment() {
    const data = allProperties.reduce(
      (acc, item) => acc + item.currentRepayment,
      0
    );
    return data;
  }

  function calculateNewRepayment() {
    const data = allProperties.reduce(
      (acc, item) => acc + item.newRepayment,
      0
    );
    return data;
  }
  function calculateTotalSavings() {
    const data = allProperties.reduce((acc, item) => acc + item.savings, 0);
    return data;
  }

  useEffect(() => {
    if (pathnames === "/home-loan") {
      setActiveTab("Home Loans");
      setResults(false);
    } else if (pathnames === "/investment-property") {
      setActiveTab("Investment Properties");
      setResults(false);
    } else if (pathnames === "/investment-property/search") {
      setResults(true);
      setActiveTab("Investment Properties");
      setCurrentListingData(allCampaigns);
    } else if (pathnames === "/home-loan/search") {
      setActiveTab("Home Loans");
      setResults(true);
    } else if (pathnames === "/home-loan/genie") {
      setActiveTab("Genie");
      setResults(false);
    } else if (pathnames === "/home-loan/run") {
      setActiveTab("Genie");
      setResults(true);
    }
  }, [pathnames]);

  useEffect(() => {
    if (searchProducts.length > 0) {
      if (investorLoans && !ownerOccupied) {
        const tmp = searchProducts.filter((c) => c.product == "investmentLoan");
        setProducts(tmp);
      } else if (ownerOccupied && !investorLoans) {
        const tmp = searchProducts.filter((c) => c.product == "ownerOccupier");
        setProducts(tmp);
      } else {
        setProducts(searchProducts);
      }
    }
  }, [searchProducts]);

  useEffect(() => {
    dispatch(
      getProperties({
        sort: `${sortBy}|${sortOrder}`,
      })
    );
  }, [sortBy, sortOrder]);

  useEffect(() => {
    if (
      allProperties.length == 0 &&
      properties.length > 0 &&
      pathnames === "/home-loan/genie"
    ) {
      const tmp = properties
        .filter((item) => item.purchases.loanAmount != 0)
        .map((item) => ({
          propertyType: item.property.type,
          propertyAddress: item.property.address.fullAddress,
          loanValue: item.purchases.loanAmount,
          product: "",
          loanType: "",
          interestType: "",
          interestRate: 0,
          currentRepayment: item.financials.loans[0].repaymentAmount,
          newRepayment: 0,
          savings: 0,
        }));
      setProperties(tmp);
    }
  }, [properties]);

  useEffect(() => {
    if (profiles._id) {
      setLead({
        ...lead,
        firstName: profiles.firstName,
        lastName: profiles.lastName,
        email: profiles.email,
        mobileNumber: profiles.mobileNumber,
      });
    }
  }, [profiles]);
  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      bedRoomMin: value[0],
      bedRoomMax: value[1],
    }));
  }, [value]);

  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      bathRoomMin: valueBath[0],
      bathRoomMax: valueBath[1],
    }));
  }, [valueBath]);

  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      garageMin: valueGarage[0],
      garageMax: valueGarage[1],
    }));
  }, [valueGarage]);

  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      landAreaMin: areaRange[0],
      landAreaMax: areaRange[1],
    }));
  }, [areaRange]);

  useEffect(() => {
    setSearch((prev) => ({
      ...prev,
      minPrice: priceRange[0],
      maxPrice: priceRange[1],
    }));
  }, [priceRange]);
  useEffect(() => {
    if (pathnames != "/investment-property/search") {
      let data = {
        address: "",
        bedRoomMin: 0,
        bedRoomMax: 6,
        bathRoomMin: 0,
        bathRoomMax: 6,
        garageMin: 0,
        garageMax: 6,
        landAreaMin: "",
        landAreaMax: "",
        minPrice: "",
        maxPrice: "",
        isFavourite: true,
      }
      dispatch(getFilteredCampaigns(data));
    }
  }, [pathnames]);
  useEffect(() => {
    setCurrentListingData(allCampaigns);
  }, [allCampaigns]);
  return (
    <Home>
      <Grid container spacing={0} px={3} mt={6}>
        <Grid item xs={12}>
          <Stack mt={2} direction={"row"} width={"100%"}>
            <IconButton onClick={() => goBack()}>
              <KeyboardBackspaceIcon
                sx={{
                  color: "#24003D",
                  cursor: "pointer",
                }}
              />
            </IconButton>
            <Stack
              sx={{
                border: "solid",
                padding: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor:
                  activeTab === "Investment Properties" ? "#24003d" : "#fff",
                borderColor: "#24003d",
                color:
                  activeTab === "Investment Properties" ? "#fff" : "#24003d",
                cursor: "pointer",
                borderRadius: "15px 0 0 0",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigate("/investment-property");
              }}
            >
              Investment Properties
            </Stack>

            <Stack
              sx={{
                border: "solid",
                padding: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderColor: "#24003d",
                backgroundColor:
                  activeTab === "Investment Properties" ? "#fff" : "#24003d",
                color:
                  activeTab === "Investment Properties" ? "#24003d" : "#fff",
                cursor: "pointer",
                borderRadius: "0 15px 0 0",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigate("/home-loan");
              }}
            >
              Home Loans
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {result === false ? (
            <>
              {activeTab === "Investment Properties" ? (
                <>
                  <Box
                    sx={{
                      bgcolor: "#fff",
                      paddingTop: 1,
                      mt: 2,
                      boxShadow: 10,
                      borderRadius: 10,
                      padding: 2,
                    }}
                  >
                    <Grid container justifyContent="center">
                      <Grid container mt={2}>
                        <Grid item xs={12} md={6}>
                          <Stack>
                            <Typography
                              sx={{
                                color: "#0f2e5a",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              Location
                            </Typography>
                            <Stack px={2}>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                >
                                  <FormControlLabel
                                    sx={{
                                      color: "#0f2e5a",
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      "& .MuiRadio-root": {
                                        color: "#24003D",
                                      },
                                      "& .MuiRadio-root.Mui-checked": {
                                        color: "#24003D",
                                      },
                                    }}
                                    value="State"
                                    control={
                                      <Radio checked={ctx === "State"} />
                                    }
                                    label="State"
                                    onClick={() => setCtx("State")}
                                  />
                                  <FormControlLabel
                                    sx={{
                                      color: "#0f2e5a",
                                      fontWeight: 600,
                                      fontSize: "16px",
                                      "& .MuiRadio-root": {
                                        color: "#24003D",
                                      },
                                      "& .MuiRadio-root.Mui-checked": {
                                        color: "#24003D",
                                      },
                                    }}
                                    value="Address"
                                    control={<Radio checked={ctx === "Address"} />}
                                    label="Address"
                                    onClick={() => setCtx("Address")}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Stack>
                            {ctx == "State" ?
                              <Autocomplete
                                defaultValue={search?.address}
                                placeholder="State"
                                style={{
                                  borderRadius: 20,
                                  minHeight: "42px",
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                  border: "1.7px solid lightgray",
                                  borderWidth: 1,
                                  boxShadow: "3px 3px 3px rgba(10, 10, 10, 0.3)",
                                }}
                                apiKey={GOOGLE_API_KEY}
                                options={{
                                  types: ["(regions)"],  
                                  componentRestrictions: { country: "aus" },
                                }}
                                onPlaceSelected={(place) => {
                                  if (!place?.geometry) {
                                    notify("Invalid address", "error");
                                    return;
                                  }
                                  const parsedAddress = {};
                                  place.address_components?.forEach((component) => {
                                    const types = component.types;
                                    if (
                                      types.includes("administrative_area_level_1")
                                    ) {
                                      parsedAddress["state"] = component.short_name;
                                    }
                                  });
                                  setSearch((data) => ({
                                    ...data,
                                    address: parsedAddress["state"],
                                  }));
                                }}
                              /> : <Autocomplete
                                defaultValue={search?.address}
                                placeholder="Address"
                                style={{
                                  borderRadius: 20,
                                  minHeight: "42px",
                                  paddingLeft: "10px",
                                  fontSize: "13px",
                                  border: "1.7px solid lightgray",
                                  borderWidth: 1,
                                  boxShadow: "3px 3px 3px rgba(10, 10, 10, 0.3)",
                                }}
                                apiKey={GOOGLE_API_KEY}
                                options={{
                                  componentRestrictions: { country: "aus" },
                                }}
                                onPlaceSelected={(place) => {
                                  if (!place?.geometry) {
                                    notify("Invalid address", "error");
                                    return;
                                  }
                                  const parsedAddress = {};
                                  place.address_components?.forEach((component) => {
                                    const types = component.types;
                                    if (
                                      types.includes("administrative_area_level_1")
                                    ) {
                                      parsedAddress["state"] = component.short_name;
                                    }
                                  });
                                  setSearch((data) => ({
                                    ...data,
                                    address: place.formatted_address,
                                  }));
                                }}
                              />}
                            {/* <TextField
                              sx={{
                                "& .MuiInputBase-input": {
                                  height: "1.5rem",
                                },
                              }}
                              InputProps={{
                                sx: {
                                  borderRadius: 5,
                                  boxShadow: 3,
                                },
                              }}
                              size="small"
                              variant="outlined"
                              type="text"
                              name={`address`}
                              placeholder="Enter Suburb,State or Postal code"
                              fullWidth
                              value={search.address}
                              onChange={(event) => {
                                const updatedAddress = event.target.value;
                                setSearch({
                                  ...search,
                                  address: updatedAddress,
                                });
                              }}
                              inputProps={{
                                style: { fontSize: "13px" },
                              }}
                            /> */}
                            {/* {errors?.lastName && (
                    <Typography
                      style={{
                        color: "#d32f2f",
                        fontSize: "0.75rem",
                        fontWeight: "400",
                        marginTop: "4px",
                      }}
                    >
                      {errors.lastName}
                    </Typography>
                  )} */}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={2}>
                        <Stack
                          aria-describedby={id}
                          onClick={handleClick}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffff",
                            boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                            padding: 2,
                            borderRadius: 2,
                            height: 50,
                            borderColor: "gray",
                            borderWidth: 1,
                            borderStyle: "solid",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          direction="row"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                color: "#0f2e5a",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              Beds: {value[0]} - {value[1]}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{
                            marginTop: 3,
                          }}
                        >
                          <Stack padding={2}>
                            <Typography sx={{ fontSize: 13 }} mb={2}>
                              Select min and max bedrooms
                            </Typography>
                            <Slider
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              valueLabelDisplay="auto"
                              step={1}
                              marks={marks}
                              min={0}
                              max={6}
                              sx={{ width: 270, mx: 2, color: "#24003d" }}
                            />
                          </Stack>
                        </Popover>
                      </Grid>

                      <Grid item xs={2}>
                        <Stack
                          aria-describedby={id}
                          onClick={handleBathroomClick}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffff",
                            boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                            padding: 2,
                            borderRadius: 2,
                            height: 50,
                            borderColor: "gray",
                            borderWidth: 1,
                            borderStyle: "solid",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          direction="row"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                color: "#0f2e5a",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              Baths: {valueBath[0]} - {valueBath[1]}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Popover
                          id={id}
                          open={openBath}
                          anchorEl={anchorElBath}
                          onClose={handleBathClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{
                            marginTop: 3,
                          }}
                        >
                          <Stack padding={2}>
                            <Typography sx={{ fontSize: 13 }} mb={2}>
                              Select min and max bathrooms
                            </Typography>
                            <Slider
                              value={valueBath}
                              onChange={(e) => setValueBath(e.target.value)}
                              valueLabelDisplay="auto"
                              step={1}
                              marks={marks}
                              min={0}
                              max={6}
                              sx={{ width: 270, mx: 2, color: "#24003d" }}
                            />
                          </Stack>
                        </Popover>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack
                          aria-describedby={id}
                          onClick={handleGarageClick}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffff",
                            boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                            padding: 2,
                            borderRadius: 2,
                            height: 50,
                            borderColor: "gray",
                            borderWidth: 1,
                            borderStyle: "solid",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          direction="row"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              sx={{
                                textTransform: "capitalize",
                                color: "#0f2e5a",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              Garages: {valueGarage[0]} - {valueGarage[1]}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Popover
                          id={id}
                          open={openGarage}
                          anchorEl={anchorElGarage}
                          onClose={handleGarageClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{
                            marginTop: 3,
                          }}
                        >
                          <Stack padding={2}>
                            <Typography sx={{ fontSize: 13 }} mb={2}>
                              Select min and max garages
                            </Typography>
                            <Slider
                              value={valueGarage}
                              onChange={(e) => setValueGarage(e.target.value)}
                              valueLabelDisplay="auto"
                              step={1}
                              marks={marks}
                              min={0}
                              max={6}
                              sx={{ width: 270, mx: 2, color: "#24003d" }}
                            />
                          </Stack>
                        </Popover>
                      </Grid>
                      <Grid item xs={3}>
                        <Stack
                          aria-describedby={id}
                          onClick={handleLandClick}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffff",
                            boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                            padding: 2,
                            borderRadius: 2,
                            height: 50,
                            borderColor: "gray",
                            borderWidth: 1,
                            borderStyle: "solid",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          direction="row"
                        >
                          {/* Display min and max price */}
                          <Typography
                            sx={{
                              color: "#0f2e5a",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Land Area (m²): {areaRange[0]} - {areaRange[1]}{" "}
                          </Typography>
                        </Stack>

                        <Popover
                          id={id}
                          open={openLand}
                          anchorEl={anchorElLand}
                          onClose={handleLandClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{
                            marginTop: 3,
                          }}
                        >
                          <Stack padding={2}>
                            <Typography sx={{ fontSize: 13 }} mb={2}>
                              Select min and max land area
                            </Typography>
                            <Slider
                              value={areaRange}
                              onChange={(e) => setAreaRange(e.target.value)}
                              valueLabelDisplay="auto"
                              min={0}
                              max={10000}
                              step={10}
                              marks={[
                                { value: 0, label: "0 m²" },
                                { value: 10000, label: "10000 m²" },
                              ]}
                              sx={{ width: 270, mx: 2, color: "#24003d" }}
                            />
                          </Stack>
                        </Popover>
                      </Grid>
                      <Grid item xs={3}>
                        <Stack
                          aria-describedby={id}
                          onClick={handleClick1}
                          sx={{
                            width: "100%",
                            backgroundColor: "#ffff",
                            boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                            padding: 2,
                            borderRadius: 2,
                            height: 50,
                            borderColor: "gray",
                            borderWidth: 1,
                            borderStyle: "solid",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          direction="row"
                        >
                          {/* Display min and max price */}
                          <Typography
                            sx={{
                              px: 2,
                              textTransform: "capitalize",
                              color: "#0f2e5a",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Price : ${formatPrice(priceRange[0])} - $
                            {formatPrice(priceRange[1])}
                          </Typography>
                        </Stack>

                        <Popover
                          id={id}
                          open={open1}
                          anchorEl={anchorEl1}
                          onClose={handleClose1}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{
                            marginTop: 3,
                          }}
                        >
                          <Stack padding={2}>
                            <Typography sx={{ fontSize: 13 }} mb={2}>
                              Select min and max price
                            </Typography>

                            <Slider
                              value={priceRange}
                              onChange={(e) => setPriceRange(e.target.value)}
                              valueLabelDisplay="auto"
                              min={0}
                              max={10000000}
                              step={10000}
                              marks={[
                                { value: 0, label: "$0" },
                                { value: 10000000, label: "$10M" },
                              ]}
                              sx={{ width: 270, mx: 2, color: "#24003d" }}
                            />
                          </Stack>
                        </Popover>
                      </Grid>
                    </Grid>

                    {/* <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} mt={2}>
                      <Stack
                        sx={{
                          width: "100%",
                          backgroundColor: "#ffff",
                          boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                          padding: 2,
                          borderRadius: 2,
                          height: 50,
                          borderColor: "gray",
                          borderWidth: 1,
                          borderStyle: "solid",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        direction="row"
                      >
                        <Stack direction="row" alignItems="center">
                          <Typography
                            sx={{
                              color: "#0f2e5a",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Bedrooms*
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleDecrement("bedRoom")}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              {search.bedRoom}
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleIncrement("bedRoom")}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mt={2}>
                      <Stack
                        sx={{
                          width: "100%",
                          backgroundColor: "#ffff",
                          boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                          padding: 2,
                          borderRadius: 2,
                          height: 50,
                          borderColor: "gray",
                          borderWidth: 1,
                          borderStyle: "solid",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        direction="row"
                      >
                        <Stack direction="row" alignItems="center">
                          <Typography
                            sx={{
                              color: "#0f2e5a",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Bathrooms*
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleDecrement("bathRoom")}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              {search.bathRoom}
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleIncrement("bathRoom")}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} mt={2}>
                      <Stack
                        sx={{
                          width: "100%",
                          backgroundColor: "#ffff",
                          boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                          padding: 2,
                          borderRadius: 2,
                          height: 50,
                          borderColor: "gray",
                          borderWidth: 1,
                          borderStyle: "solid",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        direction="row"
                      >
                        <Stack direction="row" alignItems="center">
                          <Typography
                            sx={{
                              color: "#0f2e5a",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Garages*
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleDecrement("garage")}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                                cursor: "pointer",
                              }}
                            >
                              -
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                              }}
                            >
                              {search.garage}
                            </Typography>
                          </Box>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 40 }}
                          />
                          <Box
                            sx={{
                              width: 24,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            onClick={() => handleIncrement("garage")}
                          >
                            <Typography
                              sx={{
                                color: "#000000",
                                fontWeight: 600,
                                fontSize: "16px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              +
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid> */}

                    {/* <Grid container spacing={2} py={2}>
                    <Grid item xs={4}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Land Area (m²)
                        </Typography>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <TextField
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "1.5rem",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderTopLeftRadius: "0rem",
                                borderBottomLeftRadius: "0rem",
                                borderRadius: 5,
                                boxShadow: 3,
                              },
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                            type="number"
                            name={`landArea`}
                            placeholder="Land Area"
                            value={search.landArea}
                            onChange={(e) =>
                              setSearch({ ...search, landArea: e.target.value })
                            }
                            fullWidth
                            inputProps={{
                              style: { fontSize: "13px" },
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Min Price
                        </Typography>
                        <Stack spacing={1}>
                          <CurrencyInput
                            value={search.minPrice}
                            onChange={(value) => {
                              setSearch({
                                ...search,
                                minPrice: value,
                              });
                            }}
                            placeholder="$ 000,000.00"
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            style={{
                              maxWidth: "180px",
                              fontSize: "14px",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                          
                          <RenderErrorMessage
                            errors={error}
                            type={"minPrice"}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Max Price
                        </Typography>
                        <Stack spacing={1}>
                          <CurrencyInput
                            value={search.maxPrice}
                            onChange={(value) => {
                              setSearch({
                                ...search,
                                maxPrice: value,
                              });
                            }}
                            placeholder="$ 000,000.00"
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            style={{
                              maxWidth: "180px",
                              fontSize: "14px",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                          <RenderErrorMessage
                            errors={error}
                            type={"maxPrice"}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid> */}

                    <Grid container justifyContent="center" mt={3}>
                      <Button
                        onClick={onSearch}
                        disableElevation
                        sx={{
                          border: "1px solid lightgray",
                          width: "fit-content",
                          fontWeight: 600,
                          color: "#fff",
                          bgcolor: "#24003D !important",
                          border: "1px solid #24003D !important",
                          textTransform: "none",
                          borderRadius: "5px",
                          px: 4,
                          boxShadow:
                            "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                          transition: "box-shadow 0.3s ease",
                          "&:hover": {
                            boxShadow: "none",
                          },
                        }}
                        variant="contained"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Box>
                  <FavouriteListing data={currentListingData} />
                </>
              ) : (
                activeTab === "Home Loans" && (
                  <Grid item xs={12} mt={3}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        minHeight: "50vh", // Full viewport height
                        width: 700,
                        display: "flex",
                        justifyContent: "center", // Horizontally center
                        alignItems: "center", // Vertically center
                        bgcolor: "#fff",
                        boxShadow: 10,
                        borderRadius: 10,
                        padding: 2,
                        margin: "0 auto", // Center horizontally
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center", // Horizontally center
                          alignItems: "center", // Vertically center
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <Stack
                              direction={"row"}
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Checkbox
                                checked={ownerOccupied}
                                onChange={() =>
                                  setOwnerOccupied(!ownerOccupied)
                                }
                                style={{ color: "#24003D" }}
                              />
                              <Typography>Owner Occupied</Typography>
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center", // Horizontally center
                          alignItems: "center", // Vertically center
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <Stack
                              direction={"row"}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Checkbox
                                checked={investorLoans}
                                onChange={() =>
                                  setInvestorLoans(!investorLoans)
                                }
                                style={{ color: "#24003D" }}
                              />
                              <Typography>Investor Loans</Typography>
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          display: "flex",
                          justifyContent: "center", // Horizontally center
                          alignItems: "center", // Vertically center
                        }}
                      >
                        <Button
                          onClick={() => {
                            dispatch(
                              getSearchCampaigns({
                                isOwnerOccupied: ownerOccupied,
                                isInvestorLoans: investorLoans,
                              })
                            );
                            navigate("/home-loan/search");
                          }}
                          disableElevation
                          sx={{
                            border: "1px solid lightgray",
                            width: "fit-content",
                            fontWeight: 600,
                            color: "#fff",
                            bgcolor: "#24003D !important",
                            border: "1px solid #24003D !important",
                            textTransform: "none",
                            borderRadius: "5px",
                            px: 4,
                            boxShadow:
                              "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                            transition: "box-shadow 0.3s ease",
                            "&:hover": {
                              boxShadow: "none",
                            },
                          }}
                          variant="contained"
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            </>
          ) : (
            <>
              {pathnames === "/investment-property/search" && (
                <CurrentListing data={currentListingData} search={search} />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {result && activeTab === "Home Loans" && (
            <Box>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Grid container justifyContent="end">
                      <Button
                        onClick={() => {
                          dispatch(getGenieCampaigns());
                          navigate("/home-loan/genie");
                        }}
                        disableElevation
                        sx={{
                          border: "1px solid lightgray",
                          width: "fit-content",
                          fontWeight: 600,
                          color: "#fff",
                          bgcolor: "#24003D !important",
                          border: "1px solid #24003D !important",
                          textTransform: "none",
                          borderRadius: "5px",
                          px: 4,
                          boxShadow:
                            "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                          transition: "box-shadow 0.3s ease",
                          "&:hover": {
                            boxShadow: "none",
                          },
                        }}
                        variant="contained"
                      >
                        Genie
                      </Button>
                    </Grid>
                    <Grid container justifyContent="end">
                      <Typography>
                        Calculate your repayments with Genie.
                      </Typography>
                    </Grid>
                  </Stack>
                  <Box
                    mt={3}
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      borderRadius: 4,
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <TableContainer sx={{ maxHeight: "410px" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          sx={{
                            "& th": {
                              color: "#fff",
                              backgroundColor: "#24003D",
                            },
                          }}
                        >
                          <TableRow>
                            {propertiesColumnTitles.map((column, index) => (
                              <TableCell key={index} align="left">
                                {column.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allProducts.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {
                                    PRODUCTS.find((p) => p.value == row.product)
                                      .label
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {
                                    LOAN_TYPES.find(
                                      (p) => p.value == row.loanType
                                    ).label
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  INTEREST_TYPES.find(
                                    (p) => p.value == row.interestType
                                  ).label
                                }
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {row.interestRate} %
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {row.comparisonRate} %
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  onClick={() => {
                                    setCampaignId(row.campaignId);
                                    setAgent(true);
                                  }}
                                  disableElevation
                                  sx={{
                                    border: "1px solid lightgray",
                                    width: "fit-content",
                                    fontWeight: 600,
                                    color: "#fff",
                                    bgcolor: "#24003D !important",
                                    border: "1px solid #24003D !important",
                                    textTransform: "none",
                                    borderRadius: "5px",
                                    boxShadow:
                                      "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                                    transition: "box-shadow 0.3s ease",
                                    "&:hover": {
                                      boxShadow: "none",
                                    },
                                  }}
                                  variant="contained"
                                >
                                  More Info
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          {!result && activeTab === "Genie" && (
            <Box sx={{ flexGrow: 1 }} mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      backgroundColor: "#24003d",
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 16px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffff",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Run Genie against your property portfolio !!!
                    </Typography>
                  </Stack>
                  <Box
                    marginTop={2}
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      borderRadius: 4,
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        padding: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                        }}
                      >
                        Select Campaign
                      </Typography>

                      <Select
                        defaultValue=""
                        variant="outlined"
                        size="small"
                        value={genieProducts}
                        sx={{
                          width: 150,
                          ml: 2,
                        }}
                        onChange={(e) => {
                          setGenieProducts(e.target.value);
                          const tmp = allProperties.map((item) => ({
                            propertyType: item.propertyType,
                            propertyAddress: item.propertyAddress,
                            loanValue: item.loanValue,
                            product: "",
                            loanType: "",
                            interestType: "",
                            interestRate: 0,
                            currentRepayment: item.currentRepayment,
                            newRepayment: 0,
                            savings: 0,
                          }));
                          setProperties(tmp);
                        }}
                      >
                        {genieCampaigns.length > 0 &&
                          genieCampaigns.map((c) => {
                            return (
                              <MenuItem value={c}>{c?.campaign?.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </Box>
                    <TableContainer sx={{ maxHeight: "270px" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          sx={{
                            "& th": {
                              color: "#fff",
                              backgroundColor: "#24003D",
                            },
                          }}
                        >
                          <TableRow>
                            {productsColumnTitles.map((column, index) => (
                              <TableCell key={index} align="left">
                                {column.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allProperties.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  PROPERTY_TYPES.find(
                                    (p) => p.value == row.propertyType
                                  ).label
                                }
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {row.propertyAddress}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    minWidth: "105px",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  $ {row.loanValue}.00
                                </Typography>
                              </TableCell>

                              <TableCell align="left">
                                <FormControl
                                  sx={{
                                    width: 140,
                                    height: "43px",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <CommonSelect
                                    label=""
                                    value={row.product}
                                    onChange={(value) => {
                                      let tmp = [...allProperties];
                                      tmp[idx] = {
                                        ...tmp[idx],
                                        product: value.value,
                                        loanType: "",
                                        interestType: "",
                                        interestRate: 0,
                                      };
                                      setProperties(tmp);
                                    }}
                                    options={PRODUCTS.filter((product) =>
                                      genieProducts?.products?.some(
                                        (item) => item.product === product.value
                                      )
                                    )}
                                    size="small"
                                    disabled={genieProducts?.length === 0}
                                    variant="outlined"
                                    sx={{
                                      "& .MuiSelect-select": {
                                        borderTopRightRadius: "0rem",
                                        borderBottomRightRadius: "0rem",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell align="left">
                                <FormControl
                                  sx={{
                                    width: 140,
                                    height: "43px",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <CommonSelect
                                    label=""
                                    value={row.loanType}
                                    onChange={(value) => {
                                      let tmp = [...allProperties];
                                      tmp[idx] = {
                                        ...tmp[idx],
                                        loanType: value.value,
                                        interestType: "",
                                        interestRate: 0,
                                      };
                                      setProperties(tmp);
                                    }}
                                    options={LOAN_TYPES.filter((loanType) =>
                                      genieProducts?.products?.some(
                                        (item) =>
                                          item.product === row.product &&
                                          item.loanType === loanType.value
                                      )
                                    )}
                                    size="small"
                                    disabled={genieProducts?.length === 0}
                                    variant="outlined"
                                    sx={{
                                      "& .MuiSelect-select": {
                                        borderTopRightRadius: "0rem",
                                        borderBottomRightRadius: "0rem",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell align="left">
                                <FormControl
                                  sx={{
                                    width: 140,
                                    height: "43px",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <CommonSelect
                                    label=""
                                    value={row.interestType}
                                    onChange={(value) => {
                                      let tmp = [...allProperties];
                                      let result = genieProducts?.products.find(
                                        (item) =>
                                          item.product === tmp[idx].product &&
                                          item.loanType === tmp[idx].loanType &&
                                          item.interestType === value.value
                                      );
                                      let repayment =
                                        value.value === "interestOnly"
                                          ? calculateInterestOnlyRepayment(
                                            tmp[idx].loanValue,
                                            result?.interestRate
                                          )
                                          : calculateMonthlyRepayment(
                                            tmp[idx].loanValue,
                                            result?.interestRate
                                          );

                                      tmp[idx] = {
                                        ...tmp[idx],
                                        interestType: value.value,
                                        interestRate: result?.interestRate || 0,
                                        newRepayment: repayment,
                                        savings:
                                          tmp[idx].currentRepayment - repayment,
                                      };
                                      setProperties(tmp);
                                    }}
                                    options={INTEREST_TYPES.filter(
                                      (interestType) =>
                                        genieProducts?.products?.some(
                                          (item) =>
                                            item.product === row.product &&
                                            item.loanType === row.loanType &&
                                            item.interestType ===
                                            interestType.value
                                        )
                                    )}
                                    size="small"
                                    disabled={genieProducts?.length === 0}
                                    variant="outlined"
                                    sx={{
                                      "& .MuiSelect-select": {
                                        borderTopRightRadius: "0rem",
                                        borderBottomRightRadius: "0rem",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  sx={{
                                    minWidth: "105px",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {row.interestRate}.00 %
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteRow(idx)}
                                  >
                                    Delete
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                        padding: 3,
                      }}
                    >
                      <Grid container justifyContent="end">
                        {allProperties.every(
                          (property) => property.interestType !== ""
                        ) && (
                            <Button
                              onClick={() => navigate("/home-loan/run")}
                              disableElevation
                              sx={{
                                border: "1px solid lightgray",
                                width: "fit-content",
                                fontWeight: 600,
                                color: "#fff",
                                bgcolor: "#24003D !important",
                                border: "1px solid #24003D !important",
                                textTransform: "none",
                                borderRadius: "5px",
                                px: 4,
                                boxShadow:
                                  "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                                transition: "box-shadow 0.3s ease",
                                "&:hover": {
                                  boxShadow: "none",
                                },
                              }}
                              variant="contained"
                            >
                              Run
                            </Button>
                          )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          {result && activeTab === "Genie" && (
            <Box sx={{ flexGrow: 1 }} mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      backgroundColor: "#24003d",
                      height: 40,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 16px",
                    }}
                  >
                    {calculateTotalSavings() > 0 ? (
                      <Typography
                        sx={{
                          color: "#ffff",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                      >
                        Genie says you can save $ {calculateTotalSavings()}.00
                        on{" "}
                        {
                          allProperties.filter((item) => item.savings > 0)
                            .length
                        }{" "}
                        Properties.
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#ffff",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                      >
                        Hmm...looks like you are not saving in this deal. Stay
                        tuned for better offers.
                      </Typography>
                    )}
                  </Stack>
                  <Box
                    marginTop={2}
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      borderRadius: 4,
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <TableContainer sx={{ maxHeight: "390px" }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          sx={{
                            "& th": {
                              color: "#fff",
                              backgroundColor: "#24003D",
                            },
                          }}
                        >
                          <TableRow>
                            {resultsColumnTitles.map((column, index) => (
                              <TableCell key={index} align="left">
                                {column.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allProperties.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  PROPERTY_TYPES.find(
                                    (p) => p.value == row.propertyType
                                  ).label
                                }
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {row.propertyAddress}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    minWidth: "105px",
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  $ {row.loanValue}.00
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {
                                    PRODUCTS.find((p) => p.value == row.product)
                                      .label
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "15px",
                                  }}
                                >
                                  {
                                    LOAN_TYPES.find(
                                      (p) => p.value == row.loanType
                                    ).label
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {
                                  INTEREST_TYPES.find(
                                    (p) => p.value == row.interestType
                                  ).label
                                }
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {row.interestRate} %
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                $ {row.currentRepayment}.00
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                $ {row.newRepayment}.00
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{
                                  minWidth: "105px",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                $ {row.savings}.00
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                color: "#fff",
                                backgroundColor: "#24003D",
                              },
                            }}
                          >
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              Total
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "15px",
                                }}
                              ></Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "15px",
                                }}
                              ></Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "15px",
                                }}
                              ></Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontSize: "15px",
                                }}
                              ></Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            ></TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            ></TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              $ {calculateCurrentRepayment()}.00
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              $ {calculateNewRepayment()}.00
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              $ {calculateTotalSavings()}.00
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={agent}
        onClose={() => setAgent(false)}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: "800px", // Set specific width
            borderRadius: "16px", // Set border-radius
          },
        }}
      >
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setAgent(false)}
            aria-label="close"
            style={{ position: "absolute", right: 15, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography
                mt={2}
                sx={{
                  color: "#fff",
                  fontWeight: 600,
                  fontSize: "25px",
                  backgroundColor: "#24003D",
                  padding: 1,
                }}
              >
                Submit a Query
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Stack
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid container p={2} spacing={0}>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          First Name
                        </Typography>
                        <Stack spacing={1}>
                          <TextField
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "1.5rem",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderTopLeftRadius: "0rem",
                                borderBottomLeftRadius: "0rem",
                                marginTop: 1,
                                borderRadius: 5,
                                boxShadow: 3,
                              },
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            name={`firstName`}
                            placeholder="First name"
                            value={lead.firstName}
                            onChange={(event) => {
                              setLead({
                                ...lead,
                                firstName: event.target.value,
                              });
                            }}
                            fullWidth
                            inputProps={{
                              style: { fontSize: "13px" },
                            }}
                          />
                          <Stack>
                            {error?.path == "firstName" && (
                              <Typography
                                style={{
                                  color: "purple",
                                  fontSize: "0.75rem",
                                  fontWeight: "600",
                                  marginTop: "4px",
                                }}
                              >
                                {error.message}
                              </Typography>
                            )}
                          </Stack>{" "}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Mobile
                        </Typography>
                        <Stack spacing={1}>
                          <PhoneNumberInput
                            sx={{
                              marginTop: 1,
                              borderRadius: 5,
                              boxShadow: 3,
                            }}
                            placeholder="Mobile Number"
                            value={lead.mobileNumber}
                            onChange={(event) => {
                              setLead({
                                ...lead,
                                mobileNumber: event.target.value,
                              });
                            }}
                          />
                          <Stack>
                            {error?.path == "mobileNumber" && (
                              <Typography
                                style={{
                                  color: "purple",
                                  fontSize: "0.75rem",
                                  fontWeight: "600",
                                  marginTop: "4px",
                                }}
                              >
                                {error.message}
                              </Typography>
                            )}
                          </Stack>{" "}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Contact Method
                        </Typography>
                        <Stack mt={1}>
                          <CommonSelect
                            label=""
                            formControlStyle={{
                              minWidth: 120,
                              borderRadius: "20px",
                            }}
                            value={lead.contactMethod}
                            onChange={(value) => {
                              setLead({
                                ...lead,
                                contactMethod: value.value,
                              });
                            }}
                            options={CONTACT_METHOD}
                            size="small"
                            variant="outlined"
                            sx={{
                              "& .MuiSelect-select": {
                                borderTopRightRadius: "0rem",
                                borderBottomRightRadius: "0rem",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </Stack>
                        <Stack>
                          {error?.path == "contactMethod" && (
                            <Typography
                              style={{
                                color: "purple",
                                fontSize: "0.75rem",
                                fontWeight: "600",
                                marginTop: "4px",
                              }}
                            >
                              {error.message}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Stack
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid container p={2} spacing={0}>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Last Name
                        </Typography>
                        <Stack spacing={1}>
                          <TextField
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "1.5rem",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderTopLeftRadius: "0rem",
                                borderBottomLeftRadius: "0rem",
                                marginTop: 1,
                                borderRadius: 5,
                                boxShadow: 3,
                              },
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            name={`lastName`}
                            placeholder="Last name"
                            value={lead.lastName}
                            onChange={(event) => {
                              setLead({
                                ...lead,
                                lastName: event.target.value,
                              });
                            }}
                            fullWidth
                            inputProps={{
                              style: { fontSize: "13px" },
                            }}
                          />
                          <Stack>
                            {error?.path == "lastName" && (
                              <Typography
                                style={{
                                  color: "purple",
                                  fontSize: "0.75rem",
                                  fontWeight: "600",
                                  marginTop: "4px",
                                }}
                              >
                                {error.message}
                              </Typography>
                            )}
                          </Stack>{" "}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Email ID
                        </Typography>
                        <Stack spacing={1}>
                          <TextField
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "1.5rem",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderTopLeftRadius: "0rem",
                                borderBottomLeftRadius: "0rem",
                                marginTop: 1,
                                borderRadius: 5,
                                boxShadow: 3,
                              },
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            name={`email`}
                            placeholder="Email ID"
                            value={lead.email}
                            onChange={(event) => {
                              setLead({
                                ...lead,
                                email: event.target.value,
                              });
                            }}
                            fullWidth
                            inputProps={{
                              style: { fontSize: "13px" },
                            }}
                          />
                          <Stack>
                            {error?.path == "email" && (
                              <Typography
                                style={{
                                  color: "purple",
                                  fontSize: "0.75rem",
                                  fontWeight: "600",
                                  marginTop: "4px",
                                }}
                              >
                                {error.message}
                              </Typography>
                            )}
                          </Stack>{" "}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#0f2e5a",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Notes
                        </Typography>
                        <Stack spacing={1}>
                          <TextField
                            sx={{
                              "& .MuiInputBase-input": {
                                height: "1.5rem",
                              },
                            }}
                            InputProps={{
                              sx: {
                                borderTopLeftRadius: "0rem",
                                borderBottomLeftRadius: "0rem",
                                marginTop: 1,
                                borderRadius: 5,
                                boxShadow: 3,
                              },
                            }}
                            size="small"
                            margin="normal"
                            variant="outlined"
                            type="text"
                            name={`notes`}
                            placeholder="Notes"
                            value={lead.notes}
                            onChange={(event) => {
                              setLead({
                                ...lead,
                                notes: event.target.value,
                              });
                            }}
                            fullWidth
                            inputProps={{
                              style: { fontSize: "13px" },
                            }}
                          />
                          <Stack>
                            {error?.path == "notes" && (
                              <Typography
                                style={{
                                  color: "purple",
                                  fontSize: "0.75rem",
                                  fontWeight: "600",
                                  marginTop: "4px",
                                }}
                              >
                                {error.message}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Stack ml={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#24003D" }}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                    }
                    label={
                      <Typography>
                        I agree to share my contact details with the ageant for
                        receiving additonal details on the property.
                      </Typography>
                    }
                  />
                  <Stack>
                    {!isChecked && (
                      <Typography
                        style={{
                          color: "purple",
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        Please accept the terms to submit the request.
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack
                  mt={1}
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    disableElevation
                    sx={{
                      border: "1px solid lightgray",
                      height: "45px",
                      width: "fit-content",

                      fontWeight: 600,
                      color: "#fff !important",
                      bgcolor: "#24003D !important",
                      textTransform: "none",
                      borderRadius: "5px",
                      px: 3,
                      boxShadow:
                        "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                      transition: "box-shadow 0.3s ease",
                      "&:hover": {
                        boxShadow: "none",
                      },
                    }}
                    variant="outlined"
                    onClick={handleAdd}
                  >
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Home>
  );
};

export default TownSquareSelection;
