import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../services/auth";

export const getCashFlow = createAsyncThunk(
  "cash-flow",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "cash-flow",
      method: "GET",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateCashFlow = createAsyncThunk(
  "cash-flow/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `cash-flow/create`,
      method: "POST",
      data: data.data,
    }).then((response) => {
      dispatch(getCashFlow());
      return response;
    });
    return res;
  }
);
