import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import { getCommon, updateCommon } from "./commonActions";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    loading: false,
    data: {
      ADD_PROPERTY_STEPS: [],
      ADD_SELF_PROPERTY_STEPS:[],
      HONORIFICS: [],
      PROPERTY_TYPES: [],
      FINANCIAL_YEARS:[],
      REMINDERS_FREQUENCY:[],
      BANKS: [],
      LOAN_TYPES: [],
      EXPENSE_TYPES: [],
      DOCUMENT_TYPES:[],
      PARTNER_TYPES:[],
      PROPERTY_CATEGORIES:[]
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder

      .addCase(getCommon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCommon.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.data = action?.payload?.data;
        }
      })
      .addCase(getCommon.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default commonSlice.reducer;
