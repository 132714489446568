import * as React from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CustomCheckbox = styled(Checkbox)({
  borderRadius: "50%",
  padding: 5,
  "&.Mui-checked": {
    color: "#24003d",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 1,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "4px",
    maxHeight: "10px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const PropertyPopover = ({
  selectedProperties,
  properties,
  propertyTypes,
  setSelectedProperties,
  buttonLabel = "",
  single,
  style,
}) => {
  const buttonStyles = {
    color: "#0f2e5a",
    borderColor: "#0f2e5a",
  };
  const isAllSelected = selectedProperties.length === properties.length;

  const iconStyles = {
    color: "#0f2e5a",
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const groupedProperties = properties.reduce((acc, property) => {
    const propertyType = property.property.type || "Unknown";
    if (!acc[propertyType]) {
      acc[propertyType] = [];
    }
    acc[propertyType].push(property);
    return acc;
  }, {});

  const handlePropertySelection = (property) => {
    let tmp = [...selectedProperties];
    if (tmp.includes(property._id)) {
      tmp = tmp.filter((t) => t != property._id);
    } else {
      if (single === "single") {
        tmp = [];
        tmp.push(property._id);
      } else {
        tmp.push(property._id);
      }
    }
    if (tmp.length > 0) {
      setSelectedProperties(tmp);
    }
  };

  const handleSelectAll = () => {
    let tmp = [...selectedProperties];
    if (tmp.length !== properties.length) {
      tmp = properties.map((property) => property._id);
    } else {
      tmp = [];
    }
    setSelectedProperties(tmp);
  };
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Button
            variant="outlined"
            {...bindTrigger(popupState)}
            startIcon={<KeyboardArrowDownIcon style={iconStyles} />}
            style={{ ...buttonStyles, ...style }}
          >
            <Typography sx={{ textTransform: "capitalize", fontSize: "14px" }}>
              {buttonLabel || "Select Properties"}
            </Typography>
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div>
              <div style={{ padding: "10px" }}>
                {single === "single" ? (
                  <>
                    <></>
                  </>
                ) : (
                  <>
                    <Stack direction={"row"} justifyContent={"flex-end"}>
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          color: "#24003D",
                          fontSize: "12px",
                        }}
                        onClick={(event) => {
                          handleSelectAll();
                        }}
                      >
                        {isAllSelected ? "Unselect All" : "Select All"}
                      </Button>
                    </Stack>
                  </>
                )}

                {Object.entries(groupedProperties).map(
                  ([propertyType, propertiesOfType], index) => (
                    <Accordion
                      key={index}
                      expanded={!expanded || expanded === `panel${index}`}
                      onChange={handleChange(`panel${index}`)}
                      sx={{
                        borderTop: "1px",
                        backgroundColor: "transparent",
                      }}
                    >
                      <AccordionSummary
                        aria-controls={`panel${index}d-content`}
                        id={`panel${index}d-header`}
                        sx={{
                          borderTop: "1px",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              fontWeight: 600,
                            }}
                          >
                            {propertyTypes.find(
                              (type) => type.value === propertyType
                            )?.label || propertyType}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      {propertiesOfType.map((property, propIndex) => (
                        <AccordionDetails
                          key={propIndex}
                          sx={{
                            borderTop: "1px",
                            backgroundColor: "transparent",
                            padding: "0px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            marginBottom: "5px",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            spacing={2}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <CustomCheckbox
                              checked={selectedProperties.includes(
                                property?._id
                              )}
                              onChange={() => handlePropertySelection(property)}
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<RadioButtonCheckedIcon />}
                            />
                            <Typography
                              sx={{
                                marginLeft: "0px !important",
                                fontSize: "14px",
                              }}
                            >
                              {property.property.address.fullAddress}
                            </Typography>
                          </Stack>
                        </AccordionDetails>
                      ))}
                    </Accordion>
                  )
                )}
              </div>
            </div>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default PropertyPopover;
