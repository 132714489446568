import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  createLead,
  getAllLead,
  getLead,
  updateLead,
  deleteLead,
} from "./leadActions";

const leadSlice = createSlice({
  name: "lead",
  initialState: {
    loading: false,
    leads: [],
    leadDetails: [],
    lead: {},
    pagination: {
      page: 1,
      pageSize: 10,
      pageCount: 5,
      total: 10,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.lead = action?.payload?.data;
        }
      })
      .addCase(createLead.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllLead.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.leads = action?.payload?.data?.properties;
          state.pagination = action.payload.data.meta;
        }
      })
      .addCase(getAllLead.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.leadDetails = action?.payload?.data;
          state.pagination = action.payload.meta;
        }
      })
      .addCase(getLead.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.lead = action?.payload?.data;
        }
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteLead.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.lead = action?.payload?.data;
        }
      })
      .addCase(deleteLead.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default leadSlice.reducer;
