import * as React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment"; // Import Moment.js
import styled from "styled-components";

const CustomizedDateTimePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
  }
  & .MuiOutlinedInput-root {
    border-width: 20px;
    border-radius: 20px;
  }
`;

export default function CommonDatePicker({
  onChange = () => {},
  value = new Date(),
  maxDate = null,
  minDate = null,
  disabled,
  setError=()=>{},
  ...restyle
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <CustomizedDateTimePicker
        className=""
        slotProps={{ textField: { size: "small" } }}
        style={{
          height: 10,
          marginTop: 5,
          paddingLeft: "10px",
          ...restyle.style ? restyle.style : {}
        }}
        onError={setError}
        sx={{
          borderTopLeftRadius: "0rem",
          borderBottomLeftRadius: "0rem",
          borderRadius: 5,
          boxShadow: 3,
          ...restyle.sx ? restyle.sx : {}
        }}
        disabled={disabled}
        inputStyle={{ height: "10px" }}
        views={['year', 'month', 'day']}
        format="DD/MM/YYYY"
        onChange={onChange}
        value={moment(value)} // Convert value to Moment object
        {...(maxDate ? { maxDate: moment(maxDate) } : {})} // Convert maxDate to Moment object
        {...(minDate ? { minDate: moment(minDate) } : {})} // Convert minDate to Moment object
      />
    </LocalizationProvider>
  );
}
