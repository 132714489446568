import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest from "../../services/auth";
import { getProfile } from "../profile/profileActions";

export const createLead = createAsyncThunk(
  "lead/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/lead/create",
      method: "POST",
      data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const getAllLead = createAsyncThunk(
  "lead",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/lead?${buildQueryParams(data)}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getLead = createAsyncThunk(
  "lead/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/lead/get?leadId=${data.leadId}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateLead = createAsyncThunk(
  "lead/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/lead/update/${data.id}`,
      method: "PUT",
      data: data.data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const deleteLead = createAsyncThunk(
  "lead/delete",
  async (id, { dispatch }) => {
    const res = await apiRequest({
      url: `lead/delete`,
      method: "DELETE",
      data: id,
    }).then((response) => {
      dispatch(getAllLead());
      // dispatch(getProfile());
      return response;
    });
    return res;
  }
);
