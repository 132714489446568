import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const TextFieldComponent = ({ placeholder = "", value, onChange = () => {}, inputType='text', ...resetStyle }) => {
  return (
    <>
      <TextField
        sx={{
          "& .MuiInputBase-input": {
            height: "1.5rem",
          },
          marginTop: "7px",
        }}
        value={value}
        onChange={onChange}
        {...(placeholder ? { placeholder } : {})}
        InputProps={{
          sx: {
            borderRadius: 5,
            boxShadow: 3,
            ...(resetStyle.sx ? resetStyle.sx : {}),
          },
          disableUnderline: true,
        }}
        variant="standard"
        type={inputType}
        fullWidth
        // value={data?.managements?.firstName}

        // inputProps={{
        //   style: { fontSize: "13px" },
        // }}
      />
    </>
  );
};

export default TextFieldComponent;
