import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  createProperty,
  getProperties,
  deleteProperty,
  updateProperty,
  getProperty,
  createExpense,
  createDocument,
  createIncome,
  getDocument,
  updateDocument,
  updateExpense,
  getExpense,
  getIncome,
  getDocumentsType,
  updateIncome,
  getAllDocuments,
  getStatementType,
  getfilteredProperties,
} from "./propertyActions";

const propertySlice = createSlice({
  name: "property",
  initialState: {
    loading: false,
    properties: [],
    documentsType: [],
    allDocuments: [],
    statements: [],
    property: {},
    filterProperties: [],
    income: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createProperty.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createProperty.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(createProperty.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProperties.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProperties.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.properties = action?.payload?.data;
        }
      })
      .addCase(getProperties.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getStatementType.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getStatementType.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.statements = action?.payload?.data;
        }
      })
      .addCase(getStatementType.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getAllDocuments.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllDocuments.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.allDocuments = action?.payload?.data;
        }
      })
      .addCase(getAllDocuments.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getDocumentsType.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDocumentsType.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.documentsType = action?.payload?.data;
        }
      })
      .addCase(getDocumentsType.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getProperty.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProperty.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(getProperty.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteProperty.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateProperty.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createExpense.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(createExpense.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createIncome.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createIncome.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(createIncome.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getExpense.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(getExpense.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateExpense.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(updateExpense.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getIncome.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getIncome.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.income = action?.payload?.data;
        }
      })
      .addCase(getIncome.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateIncome.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateIncome.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.property = action?.payload?.data;
        }
      })
      .addCase(updateIncome.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getfilteredProperties.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getfilteredProperties.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.properties = action?.payload?.data;
        }
      })
      .addCase(getfilteredProperties.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default propertySlice.reducer;
