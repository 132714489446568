import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  createNotification,
  getNotification,
  deleteNotification,
  updateNotification,
  getAllNotification,
  clearNotification,
} from "./notificationActions";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    loading: false,
    notifications: [],
    statements: [],
    notification: {},
    filterNotifications: [],
  },
  reducers: {
    updateNewNotification: (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
    },
  },
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notification = action?.payload?.data;
        }
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notifications = action?.payload?.data.reverse();
        }
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getAllNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notifications = action?.payload?.data.reverse();
        }
      })
      .addCase(getAllNotification.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notification = action?.payload?.data;
        }
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
      })

    
      .addCase(clearNotification.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(clearNotification.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(clearNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateNotification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notification = action?.payload?.data;
        }
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { updateNewNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
