import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { cookies } from "../../services/auth";

export const login = createAsyncThunk(
  "auth/login",
  async ({ data, onSuccess = () => {} }, { dispatch }) => {
    const res = await apiRequest({
      url: "auth/login",
      method: "POST",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const loginGoogle = createAsyncThunk(
  "auth/logingoogle",
  async ({ data, onSuccess = () => {} }, { dispatch }) => {
    const res = await apiRequest({
      url: window.location.pathname.startsWith("/admin")
        ? "/auth/admin-google-auth"
        : "/auth/google-auth",
      method: "POST",
      data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
