import { Box, Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PercentageInput from "../../../common/PercentageInput";

import CurrencyInput from "../../../common/CurrencyInput";
import TextFieldComponent from "../../../common/TextFieldComponent";
import CapitalGrowthChart from "../../../components/CapitalForecastChart";
import PropertyPopover from "../../../components/PropertyPopover";

import { getProperties } from "../../../store/property/propertyActions";

const PROPERTIES = [{ label: "Properties", value: "Properties" }];

const CapitalGrowthCalculator = ({ hide }) => {
  const dispatch = useDispatch();
  const [selectProperties, setSelectProperties] = useState({
    propertyValue: "",
  });
  const { PROPERTY_TYPES } = useSelector((state) => state.common.data);

  const [selectPropertiesId, setSelectPropertiesId] = useState(null);
  const [investment, setInvestment] = useState(null);
  const [growth, setGrowth] = useState(null);
  const [investmentPeriod, setInvestmentPeriod] = useState(null);
  const [calculateData, setCalculateData] = useState({
    average: null,
    grwoth: null,
    value: null,
  });
  const [selectedProperties, setSelectedProperties] = React.useState([]);

  const { FINANCIAL_YEARS } = useSelector((state) => state.common.data);
  const properties = useSelector((state) => state.properties.properties);

  const property = useSelector((state) => state.properties.property);

  const onChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue <= 30) {
      setInvestmentPeriod(inputValue);
    }
  };

  const onCalculator = () => {
    const initialInvestment = investment || property?.purchases?.purchasePrice;
    const percentage = growth;
    const period = investmentPeriod;
    const growthRate = 1 + percentage / 100;
    const futureValue = initialInvestment * Math.pow(growthRate, period);
    const capitalGrowth = futureValue - initialInvestment;
    const capitalGrowthPerAnnum = capitalGrowth / period;
    const growthPerAnnum = capitalGrowthPerAnnum;
    const growthInvestment = growthPerAnnum * investmentPeriod;
    const growthValue = Number(growthInvestment) + Number(initialInvestment);
    setCalculateData({
      average: growthPerAnnum,
      growth: growthInvestment,
      value: growthValue,
    });
  };

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (investment && growth && investmentPeriod) {
      onCalculator();
    }
  }, [investment, growth, investmentPeriod]);

  const generateChartData = (investmentPeriod, growth, investment) => {
    let capitalGrowthOverLife = 0;

    let result = [];

    for (let i = 0; i <= investmentPeriod; i++) {
      result.push({
        name: i.toString(),
        averageCapitalGrowth: investment,
        capitalGrowthOverLife: capitalGrowthOverLife.toFixed(2),
      });

      capitalGrowthOverLife += calculateData.average;
    }
    return result;
  };

  // const generateChartData = (investmentPeriod, growth, investment) => {
  //   let capitalGrowthOverLife = 0;

  //   let result = [];

  //   for (let i = 0; i <= investmentPeriod; i++) {
  //     result.push({
  //       name: i.toString(),
  //       averageCapitalGrowth: investment.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //       }),
  //       capitalGrowthOverLife: capitalGrowthOverLife.toLocaleString("en-US", {
  //         style: "currency",
  //         currency: "USD",
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //       }),
  //     });

  //     capitalGrowthOverLife += calculateData.average;
  //   }
  //   return result;
  // };

  // const generateChartData = (
  //   numberOfYears = investmentPeriod,
  //   capitalGrowthRate = calculateData.average,
  //   initialInvestment = calculateData.value // Use the final value as the initial investment for the next year
  // ) => {
  //   const years = Array.from(
  //     { length: Number(numberOfYears) + 1 },
  //     (_, i) => i
  //   );

  //   const data = years.map((year) => {
  //     const averageCapitalGrowth =
  //       initialInvestment * Math.pow(1 + Number(capitalGrowthRate), year);
  //     const capitalGrowthOverLife = averageCapitalGrowth - initialInvestment;
  //     const propertyValue = averageCapitalGrowth;

  //     // Update initialInvestment for the next iteration
  //     initialInvestment = averageCapitalGrowth;

  //     return {
  //       name: `${year}`,
  //       averageCapitalGrowth: +averageCapitalGrowth.toFixed(2),
  //       capitalGrowthOverLife: +capitalGrowthOverLife.toFixed(2),
  //       propertyValue: +propertyValue.toFixed(2),
  //     };
  //   });

  //   return data;
  // };
  return (
    <>
      <Box
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: 4,
          backgroundColor: "#ffffff",
        }}
      >
        <Stack padding={4} mt={2}>
          <Stack
            mt={2}
            px={1}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            width={"100%"}
          ></Stack>
          <Grid container spacing={2} px={1} mt={3}>
            <Grid item md={5} xs={12}>
              <Stack>
                <Typography
                  sx={{
                    color: "#0f2e5a",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Properties
                </Typography>

                <Stack direction={"row"}>
                  <Stack>
                    <PropertyPopover
                      properties={properties}
                      propertyTypes={PROPERTY_TYPES}
                      setSelectedProperties={(prop) => {
                        if (prop.length > 0) {
                          setInvestment(
                            properties.find((p) => p._id === prop[0]).purchases
                              .purchasePrice
                          );
                          setSelectedProperties([prop[0]]);
                        }
                      }}
                      selectedProperties={selectedProperties}
                      single={"single"}
                      buttonLabel={
                        properties.find(
                          (p) =>
                            p._id ==
                            (selectedProperties.length > 0 &&
                              selectedProperties[0])
                        )?.property.address.fullAddress || ""
                      }
                    />
                  </Stack>
                </Stack>
                {/* <CommonSelect
                value={selectProperties.propertyValue}
                style={{ width: "fullWidth" }}
                onChange={(value) => {
                  setSelectProperties({
                    ...selectProperties,
                    propertyValue: value.value,
                  });

                  setSelectPropertiesId({
                    ...selectPropertiesId,
                    propertyId: value._id,
                  });
                }}
                options={propertiesOptions || []}
              /> */}
              </Stack>
              <Stack spacing={2} mt={2}>
                <Stack>
                  <Typography
                    sx={{
                      color: "#0f2e5a",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    Initial Investment ($)
                  </Typography>
                  <CurrencyInput
                    placeholder="$"
                    value={investment}
                    onChange={(data) => setInvestment(data)}
                    sx={{ maxWidth: "350px" }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      color: "#0f2e5a",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    Capital Growth (%)
                  </Typography>
                  <PercentageInput
                    placeholder="%"
                    sx={{
                      maxWidth: "350px",
                    }}
                    // style={{fontSize:'30px'}}
                    onChange={(value) => {
                      setGrowth(value);
                    }}
                  />
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      color: "#0f2e5a",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    Investment period (Yrs)
                  </Typography>
                  <TextFieldComponent
                    placeholder="1 to 30"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={investmentPeriod}
                    // onChange={(value) => {
                    //   setPeriod({
                    //     ...period,
                    //     investmentPeriod: value,
                    //   });
                    // }}
                    inputType="number"
                    inputProps={{
                      min: 1,
                      max: 30,
                      step: 1,
                    }}
                    sx={{
                      paddingLeft: 1,
                      paddingRight: 1,
                      height: "41px",
                      maxWidth: "350px",
                      fontSize: "16px",
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={7} xs={12}>
              <Stack p={2}>
                <CapitalGrowthChart
                  data={generateChartData(
                    Number(investmentPeriod),
                    Number(growth),
                    Number(investment)
                  )}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2} padding={1}>
            <Grid item md={6} xs={12}>
            </Grid>

            <Grid item md={6} xs={12}>
              <Stack flexDirection={"row"} justifyContent={"center"}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Stack
                    ml={3}
                    sx={{
                      width: 30,
                      height: 10,
                      backgroundColor: "#b8b5e8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Stack>
                  <Typography px={1}>Investment</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Stack
                    ml={3}
                    sx={{
                      width: 30,
                      height: 10,
                      backgroundColor: "#b4dfc4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Stack>
                  <Typography px={1}>Growth</Typography>
                </Box>
              </Stack>
              <Stack spacing={2} mt={2}>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                    Average Captal Growth p.a
                  </Typography>
                  <Typography>
                    {calculateData?.average?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                    Capital Growth over life of Investment
                  </Typography>
                  <Typography>
                    {calculateData?.growth?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  sx={{
                    display: hide === "hide" ? "none" : "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                    Your Property Value
                  </Typography>
                  <Typography>
                    {calculateData?.value?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default CapitalGrowthCalculator;
