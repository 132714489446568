import React from "react";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";

function CommonSelect({
  label,
  value,
  onChange,
  options,
  placeholder = "Select an option",
  formControlStyle = {},
  ...restProps
}) {
  if (!restProps) restProps = {};
  return (
    <FormControl style={formControlStyle}>
      {label && (
        <Typography
          sx={{
            color: "#0f2e5a",
            fontWeight: 600,
            fontSize: "16px",
            marginBottom: 1,
          }}
        >
          {label}
        </Typography>
      )}
      <Select
        size="small"
        value={value}
        displayEmpty
        onChange={(e) => {
          onChange(options.find((o) => o.value === e.target.value) || null);
        }}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <em
                style={{
                  fontSize: "13px",
                  fontFamily: `"Inter", "system-ui", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                }}
              >
                {placeholder}
              </em>
            );
          }
          const selectedOption = options?.find(
            (option) => option.value === selected
          );
          return selectedOption ? selectedOption.label : <em>{placeholder}</em>;
        }}
        {...restProps}
        InputLabelProps={{ style: { fontSize: "13px" } }}
        sx={{
          borderTopLeftRadius: "0rem",
          borderBottomLeftRadius: "0rem",
          borderRadius: 5,
          boxShadow: 3,
        }}
      >
        {options?.map((option, idx) => (
          <MenuItem
            key={`${option.label}-${idx}`}
            value={option.value}
            sx={{ fontSize: "13px" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CommonSelect;
