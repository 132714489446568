import { Stack, Typography } from "@mui/material";
import React from "react";

const RenderErrorMessage = ({ type, errors }) => {
  const error = errors.length > 0 && errors.find(({ path }) =>
    typeof type == "string" ? path == type : type.includes(path)
  );
  return (
    <Stack>
      {error && (
        <Typography
          style={{
            color: "purple",
            fontSize: "0.75rem",
            fontWeight: "600",
            marginTop: "4px",
          }}
        >
          {error.message}
        </Typography>
      )}
    </Stack>
  );
};

export default RenderErrorMessage;
