import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, { cookies } from "../../services/auth";

export const getPortfolios = createAsyncThunk(
  "property/portfolio-holding",
  async ({ startDate, endDate }, { dispatch }) => {
    // Destructure your data object
    const res = await apiRequest({
      url: "/analytics/portfolio-holding",
      method: "GET",
      params: { startDate, endDate }, // Use params for GET requests
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPortfolioProperties = createAsyncThunk(
  "property/portfolio-properties",
  async ({ startDate, endDate }, { dispatch }) => {
    const res = await apiRequest({
      url: "/analytics/portfolio-properties",
      method: "GET",
      params: { startDate, endDate },
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPropertyChart = createAsyncThunk(
  "analytics/portfolio-cashflow",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/analytics/portfolio-cashflow?property=${data.propertyId}`,
      method: "GET",
      params: { startDate: data.startDate, endDate: data.endDate },
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPortfolioExpenseCategories = createAsyncThunk(
  "property/portfolio-expense-categories",
  async ({ startDate, endDate }, { dispatch }) => {
    const res = await apiRequest({
      url: "/analytics/portfolio-expense-categories",
      method: "GET",
      params: {  startDate, endDate },
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPortfolioIncomeExpense = createAsyncThunk(
  "property/portfolio-income-expense",
  async ({ startDate, endDate }, { dispatch }) => {
    const res = await apiRequest({
      url: "/analytics/portfolio-income-expense",
      method: "GET",
      params: { startDate, endDate },
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPortfolioHealthLvr = createAsyncThunk(
  "property/portfolio-health-lvr",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/analytics/portfolio-health-lvr",
      method: "GET",
      params: { startDate: data.startDate, endDate: data.endDate },
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPortfolioRentalYield = createAsyncThunk(
  "property/portfolio-rental-yield",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/analytics/portfolio-rental-yield",
      method: "GET",
      params: { startDate: data.startDate, endDate: data.endDate },
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPortfolioListings = createAsyncThunk(
  "property/portfolio-listings",
  async () => {
    const res = await apiRequest({
      url: "/analytics/portfolio-listings",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getPortfolioLeadStatus = createAsyncThunk(
  "property/portfolio-lead-status",
  async () => {
    const res = await apiRequest({
      url: "/analytics/portfolio-lead-status",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getPortfolioLeads = createAsyncThunk(
  "property/portfolio-leads",
  async () => {
    const res = await apiRequest({
      url: "/analytics/portfolio-leads",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getPortfolioNotes = createAsyncThunk(
  "property/portfolio-notes",
  async () => {
    const res = await apiRequest({
      url: "/analytics/portfolio-notes",
      method: "GET",
    }).then((response) => {
      return response;
    });
    return res;
  }
);