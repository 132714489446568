import React, { useEffect } from "react";
// import { BrowserRouter } from "react-router-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Routes from "./routes/index";
import { CookiesProvider } from "react-cookie";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./store";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import apiRequest from "./services/auth/test";
import { STRIPE_PRIVATE_KEY } from "./constants/portConstants";
const stripePromise = loadStripe(STRIPE_PRIVATE_KEY);

const theme = createTheme({
  typography: {
    allVariants: {
      // fontFamily: `"Montserrat","sans-serif"`,
      fontFamily: `"Inter", "system-ui", "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
      fontSize: 15,
    },
  },
});

const App = () => {
  return (
    <>
      {/* <div style={{ background: "#d2e2fd2b" }}> */}
      <Provider store={store}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <GoogleOAuthProvider clientId="1010458556189-n88gght6u5n58pe5pgn356hk0scbmls4.apps.googleusercontent.com">
                <Elements stripe={stripePromise}>
                  <Routes />
                </Elements>
              </GoogleOAuthProvider>
            </BrowserRouter>
          </ThemeProvider>
        </CookiesProvider>
      </Provider>

      {/* </div> */}
      <ToastContainer
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
        style={{ color: "black" }}
        progressStyle={{ backgroundColor: "#24003d" }}
      />
    </>
  );
};

export default App;
