import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest from "../../services/auth";
import { getProfile } from "../profile/profileActions";

export const createNote = createAsyncThunk(
  "note/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/note/create",
      method: "POST",
      data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const getAllNote = createAsyncThunk(
  "note",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/note`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getNote = createAsyncThunk(
  "note/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/note/get?leadId=${data.leadId}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateNote = createAsyncThunk(
  "note/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/note/update/${data.id}`,
      method: "PUT",
      data: data.data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const deleteNote = createAsyncThunk(
  "note/delete",
  async (id, { dispatch }) => {
    const res = await apiRequest({
      url: `note/delete`,
      method: "DELETE",
      data: id,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
