import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function Example({ data }) {
  // ... (Previous code remains unchanged)

  // const data = [
  //   {
  //     name: "0",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 0,
  //     propertyValue: 10000,
  //   },
  //   {
  //     name: "1",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 11600,
  //     propertyValue: 110000,
  //   },
  //   {
  //     name: "2",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 13200,
  //     propertyValue: 1210000,
  //   },
  //   {
  //     name: "3",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 14600,
  //     propertyValue: 13310000,
  //   },
  //   {
  //     name: "4",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 16200,
  //     propertyValue: 146410000,
  //   },
  //   {
  //     name: "5",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 17800,
  //     propertyValue: 1610510000,
  //   },
  //   {
  //     name: "6",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 19400,
  //     propertyValue: 17715610000,
  //   },
  //   {
  //     name: "7",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 21000,
  //     propertyValue: 194871710000,
  //   },
  //   {
  //     name: "8",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 22600,
  //     propertyValue: 2143588810000,
  //   },
  //   {
  //     name: "9",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 24200,
  //     propertyValue: 23579476910000,
  //   },
  //   {
  //     name: "10",
  //     averageCapitalGrowth: 10000,
  //     capitalGrowthOverLife: 25800,
  //     propertyValue: 259374246010000,
  //   },
  // ];
  const formatYAxis = (tick) => `$${tick.toLocaleString()}`;
  const tooltipFormatter = (value, name) => {
    return [value !== null && `$${value.toLocaleString()}`, name];
  };
  return (
    <ResponsiveContainer width="100%" height={400} maxWidth={640}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatYAxis} width={100} />
        <Tooltip formatter={tooltipFormatter} />
        <Area
          type="monotone"
          dataKey="averageCapitalGrowth"
          stackId="1"
          stroke="#8884d8"
          fill="#8884d8"
          name="Investment"
        />
        <Area
          type="monotone"
          dataKey="capitalGrowthOverLife"
          stackId="1"
          stroke="#82ca9d"
          fill="#82ca9d"
          name="Growth"
        />
        {/* Add more Areas if you have more data sets */}
      </AreaChart>
    </ResponsiveContainer>
  );
}
