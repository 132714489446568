import {
  Badge,
  Button,
  FormControl,
  TextField,
  IconButton,
  Modal,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomSwitch from "../../common/CustomSwitch";
// import Logo from "../../assests/mainLogo.png";
// import Logo from "../../assests/logo/crop/white_logo_transparent.png";
// import Logo from "../../assests/logo/New-crop/white_transparent_logo.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CachedIcon from "@mui/icons-material/Cached";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { DayCalendarSkeleton, PickersDay } from "@mui/x-date-pickers";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CommonDatePicker from "../../common/CommonDatePicker";
import CommonSelect from "../../common/CommonSelect";
import { capitalizeFirstLetter } from "../../helpers/functions";
import {
  createReminder,
  deleteReminder,
  getAllReminder,
} from "../../store/reminder/reminderActions";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { updateFilter } from "../../store/reminder/reminderReducers";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";

const reminderStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 3,
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ServerDay(props) {
  const { reminderDates = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && reminderDates.indexOf(props.day.date()) >= 0;

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? <div class="circle-emoji"></div> : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

const Reminders = ({ anchor, toggleDrawer }) => {
  const drawerRef = useRef(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const [selectedReminderId, setSelectedReminderId] = useState(null);
  const [data, setData] = useState({
    topic: "",
    date: new Date(),
    notes: "",
    recurringReminder: false,
    frequency: "",
    endDate: new Date(),
    _id: null,
  });
  const [notificationValue, setNotificationValue] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const [openReminder, setOpenRemider] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });

  const dispatch = useDispatch();
  const location = useLocation();

  const { allReminders: reminders, reminderDates } = useSelector(
    (state) => state.reminders
  );
  const filter = useSelector((state) => state.reminders.filter, shallowEqual);
  const { REMINDERS_FREQUENCY } = useSelector((state) => state.common.data);

  const onAddDocument = async () => {
    try {
      dispatch(createReminder({ ...data })).then((response) => {
        if (response?.payload?.success) {
          setTimeout(() => {
            setOpenRemider(false);
            setData({
              topic: "",
              date: new Date(),
              notes: "",
              recurringReminder: false,
              frequency: "",
              endDate: new Date(),
              _id: null,
            });
          }, 500);
        }
      });
    } catch (error) {
      console.error("Error occurred during validation:", error);
    }
  };

  const onCalanderChange = (date, newDate) => {
    setData({
      ...data,
      date: date.toDate(),
    });
    setCalendarDate(date);
  };

  useEffect(() => {
    dispatch(getAllReminder());
  }, [filter]);

  useEffect(() => {
    dispatch(
      updateFilter({
        selectedDate: calendarDate.format("DD/MM/YYYY"),
        startDate: calendarDate.startOf("month").format("DD/MM/YYYY"),
        endDate: calendarDate.endOf("month").format("DD/MM/YYYY"),
      })
    );
  }, [calendarDate]);

  return (
    <>
      <Stack px={2}>
        <List>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <CalendarTodayIcon sx={{ fontSize: 20 }} />
              <Typography sx={{ fontWeight: "bold" }}> Calendar</Typography>
            </Stack>
            <CloseIcon
              onClick={toggleDrawer(anchor, false)}
              sx={{ fontSize: 20, cursor: "pointer" }}
            />
          </Stack>
        </List>
      </Stack>
      <Divider />
      <Stack sx={{ backgroundColor: "#edeff6", padding: 0 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateCalendar
            value={calendarDate}
            loading={isLoading}
            onMonthChange={onCalanderChange}
            onChange={onCalanderChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                reminderDates,
              },
            }}
          />
        </LocalizationProvider>
      </Stack>
      <Box
        sx={{
          minHeight: "100vh",
          position: "relative",
          width: anchor === "top" || anchor === "bottom" ? "auto" : 380,
        }}
        role="presentation"
      >
        <Stack pt={2} alignItems={"flex-end"}>
          <Button
            variant="contained"
            disableElevation
            onClick={() => setOpenRemider(true)}
            sx={{
              border: "1px solid lightgray",
              width: "fit-content",
              fontWeight: 600,
              color: "#fff",
              bgcolor: "#24003D !important",
              border: "1px solid #24003D !important",
              textTransform: "none",
              borderRadius: "5px",
              px: 3,
              boxShadow:
                "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
              transition: "box-shadow 0.3s ease",
              "&:hover": {
                boxShadow: "none",
              },
            }}
          >
            New Reminder
          </Button>
        </Stack>
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={notificationValue}
            onChange={(a, newValue) => setNotificationValue(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="ALL" {...a11yProps(0)} />
            <Tab label="OVERDUE" {...a11yProps(1)} />
            <Tab label="UPCOMING" {...a11yProps(2)} />
          </Tabs>
        </Box> */}
        {/* <CustomTabPanel value={notificationValue} index={0}> */}
        <Stack
          spacing={2}
          p={1}
          sx={{
            position: "relative",
          }}
        >
          {reminders.map((item) => {
            return (
              <Stack>
                <Stack
                  p={2}
                  spacing={1}
                  sx={{
                    border: "solid",
                    borderWidth: "2px",
                    borderColor: "#edeff6",
                    borderRadius: 2,
                    borderLeft: "solid",
                    borderLeftWidth: "5px",
                    borderLeftColor: "#24003d",
                  }}
                >
                  <Stack>
                    <Stack
                      pb={1}
                      direction={"row"}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* <VisibilityIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          {
                          }
                        }}
                      /> */}

                      <IconButton
                        onClick={() => {
                          setData({
                            topic: item.topic,
                            date: moment(item.date).toDate(),
                            notes: item.notes,
                            recurringReminder: item.recurringReminder,
                            frequency: item.frequency ? item.frequency : "",
                            endDate: item.endDate
                              ? moment(item.endDate).toDate()
                              : new Date(),
                            _id: item._id,
                          });

                          setOpenRemider(true);
                        }}
                      >
                        <EditIcon sx={{ fontSize: 20 }} />
                      </IconButton>

                      <IconButton
                        onClick={() => setSelectedReminderId(item._id)}
                      >
                        <DeleteIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Stack>
                    <Stack>
                      <Stack direction={"row"} spacing={1}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {item.topic}
                        </Typography>
                      </Stack>
                      <Typography> {item.notes}</Typography>
                    </Stack>
                    <Stack
                      direction={"column"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></Stack>
                  </Stack>
                  <Stack>
                    <Typography sx={{ color: "grey", fontStyle: "italic" }}>
                      {moment(item.date).isAfter(moment(), "day")
                        ? moment(item.date).format("DD/MM/YYYY")
                        : moment(item.date).fromNow()}
                    </Typography>
                    {/* {item.recurringReminder && <Typography sx={{ color: "grey", fontStyle: "italic" }}>
                      {item.recurringReminder && item.frequency && item.endDate
                        ? `A ${item.frequency} reminder ends on ${moment(
                            item.endDate
                          ).format("DD/MM/YYYY")}`
                        : ""}
                    </Typography>} */}
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Box>
      <React.Fragment>
        <Dialog
          open={openReminder}
          onClose={() => setOpenRemider(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {data._id ? "Edit Reminder" : "+ Add  Reminder"}
          </DialogTitle>
          <DialogContent>
            <Stack sx={{ width: "400px" }}>
              <Stack>
                <Typography
                  sx={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: "16px",
                    mb: 1,
                  }}
                >
                  Topic
                </Typography>
                <TextField
                  // disabled={isDisable === true ? true : false}
                  sx={{
                    "& .MuiInputBase-input": { height: "1.5rem" },
                    marginTop: "0px",
                  }}
                  InputProps={{
                    sx: {
                      borderTopLeftRadius: "0rem",
                      borderBottomLeftRadius: "0rem",
                      borderRadius: 5,
                      boxShadow: 3,
                    },
                  }}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  name="description"
                  placeholder="Topic"
                  fullWidth
                  value={data.topic}
                  onChange={(event) => {
                    const updatedDescription = event.target.value;
                    setData({
                      ...data,
                      topic: updatedDescription,
                    });
                  }}
                />
              </Stack>

              <Stack
                spacing={2}
                direction={"row"}
                sx={{
                  width: "100%",
                  isplay: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: "16px",
                      mb: 1,
                    }}
                  >
                    Date & Time
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        value={moment(data.date)}
                        minDateTime={data._id ? moment(data.date) : moment()}
                        onChange={(newValue) =>
                          setData({ ...data, date: newValue })
                        }
                        renderInput={(params) => <TextField {...params} />}
                        inputStyle={{ height: "10px" }}
                        views={["year", "month", "day", "hours", "minutes"]}
                        style={{
                          borderRadius: "20px",
                        }}
                        // label="Date and time"
                        format="DD/MM/YYYY hh:mm A"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  py={1}
                  sx={{
                    color: "#000",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Notes
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  placeholder="Enter notes"
                  value={data.notes}
                  onChange={(event) => {
                    const updatedNotes = event.target.value;
                    setData({
                      ...data,
                      notes: updatedNotes,
                    });
                  }}
                />
              </Stack>

              <Grid container spacing={2} mt={1}>
                <Grid item xs={7}>
                  <Typography
                    mt={1}
                    sx={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: "16px",
                    }}
                  >
                    Is this a recurring reminder?
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Stack spacing={2}>
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          value={data?.recurringReminder == true ? "yes" : "no"}
                          onChange={(event) => {
                            setData({
                              ...data,
                              recurringReminder:
                                event.target.value == "yes" ? true : false,
                            });
                            // setShowMessage(
                            //   event.target.value == "yes" ? true : false
                            // );
                          }}
                        />
                      }
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Stack sx={{ width: "100%" }} py={1}>
                {data?.recurringReminder && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Stack>
                        <FormControl fullWidth>
                          <Typography
                            sx={{
                              color: "#000",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                          >
                            Frequency
                          </Typography>

                          <CommonSelect
                            label=""
                            value={data.frequency}
                            onChange={(event) => {
                              setData({
                                ...data,
                                frequency: event.value,
                              });
                            }}
                            options={REMINDERS_FREQUENCY}
                            size="small"
                            variant="outlined"
                            sx={{
                              "& .MuiSelect-select": {
                                borderTopRightRadius: "0rem",
                                borderBottomRightRadius: "0rem",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography
                          sx={{
                            color: "#000",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          End Date
                        </Typography>
                        <CommonDatePicker
                          style={{
                            borderRadius: "20px",
                          }}
                          value={data.endDate}
                          onChange={(event) => {
                            const updatedEndDate = event;
                            setData({
                              ...data,
                              endDate: updatedEndDate,
                            });
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                )}
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={1} sx={{ display: "flex" }}>
              <Button
                onClick={() => setOpenRemider(false)}
                sx={{
                  borderColor: "#24003d",
                  color: "#24003d",
                  border: "solid",
                  borderWidth: 1,
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={onAddDocument}
                variant="contained"
                disableElevation
                sx={{ backgroundColor: "#24003d" }}
              >
                Save
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Modal
          open={!!selectedReminderId}
          onClose={() => setSelectedReminderId(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={reminderStyle}>
            <Stack
              // py={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CloseIcon
                sx={{ fontSize: 30, cursor: "pointer" }}
                onClick={() => setSelectedReminderId(null)}
              />
            </Stack>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#000",
                textAlign: "center",
              }}
            >
              Confirm Deletion
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to delete this reminder?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 2,
                gap: 2,
              }}
            >
              <Button
                sx={{
                  border: "1px solid lightgray",
                  width: "fit-content",

                  fontWeight: 600,
                  letterSpacing: 0,
                  color: "#000",
                  bgcolor: "#fff !important",
                  textTransform: "none",
                  border: "2px solid #24003D !important",
                  borderRadius: "8px",
                }}
                onClick={() => {
                  setSelectedReminderId(null);
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  // width: "80px",
                  width: "fit-content",

                  fontWeight: 600,
                  letterSpacing: 0,
                  // fontSize:'1rem',
                  color: "#fff",
                  bgcolor: "#fff !important",
                  px: 4,
                  textTransform: "none",
                  borderRadius: "5px",
                  bgcolor: "#24003D !important",
                  border: "2px solid #fff !important",
                  "&:hover": {
                    backgroundColor: "#24003D",
                  },
                }}
                variant="outlined"
                onClick={() => {
                  dispatch(deleteReminder([selectedReminderId]));
                  setSelectedReminderId(null); // Close the modal after deletion
                }}
                color="error"
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default Reminders;
