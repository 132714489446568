import React, { useEffect, useState } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getProperty } from "../../store/property/propertyActions";
import { capitalizeFirstLetter } from "../../helpers/functions";
const formatBreadcrumb = (str) => {
  return str
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
};

const isNumeric = (str) => {
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const BreadcrumbsRoutes = ({ routes }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  let pathnames = location.pathname.split("/").filter((x) => x);
  const property = useSelector((state) => state.properties.property);
  const campaign = useSelector((state) => state.campaign.campaign);

  const { DOCUMENT_TYPES } = useSelector((state) => state.common.data);
  const { PROPERTY_TYPES } = useSelector((state) => state.common.data);
  const breadcrumbCorrections = {
    "summary-view": "reports",
    "monthly-view": "reports",
    "income-and-expense-breakdown-statement": "reports",
    "EOFY-statement": "reports",
    "profit-and-loss-summary": "reports",
    "financial-position-assets-and-liabilities-statement": "reports",
    "rental-yield": "reports",
    "capital-growth": "reports",
    "lvr-projections": "reports",
    "invesment-projections": "reports",
    "cashflow-projections": "reports",
    "loan-repayment-calculator": "reports",
    "refinance-calculator": "reports",
    "add-property": "property",
  };

  const breadcrumbNames = {
    property: "Property Details",
  };

  Object.entries(breadcrumbCorrections).forEach(([key, parent]) => {
    if (pathnames.includes(key)) {
      const index = pathnames.indexOf(key);
      if (index > 0 && pathnames[index - 1] !== parent) {
        pathnames.splice(index, 0, parent);
      } else if (index === 0) {
        pathnames.unshift(parent);
      }
    } else if (pathnames.includes("note")) {
      pathnames.splice(-2);
    }
  });

  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        {location.pathname !== "/dashboard" ? (
          <Link
            component={RouterLink}
            to="/dashboard"
            underline="hover"
            color="inherit"
          >
            Home
          </Link>
        ) : (
          <Typography></Typography>
        )}
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;

          if (isNumeric(value)) {
            return last ? (
              <Typography key={to} color="text.primary">
                {breadcrumbNames[pathnames[index - 1]] ||
                  formatBreadcrumb(value)}
              </Typography>
            ) : null;
          }

          const formattedValue =
            breadcrumbNames[value] || formatBreadcrumb(value);
          let displayValue =
            property && property._id && property._id.includes(formattedValue)
              ? property?.property?.address?.fullAddress
              : campaign &&
                campaign._id &&
                campaign?._id.includes(formattedValue)
              ? campaign?.address?.fullAddress
              : formattedValue;

          if (formattedValue === "Property Details") {
            return (
              <Typography key={to} color="text.primary">
                {displayValue}
              </Typography>
            );
          }

          displayValue =
            DOCUMENT_TYPES.find(
              (dt) => capitalizeFirstLetter(dt.value) === displayValue
            )?.label || displayValue;

          return last ? (
            <Typography key={to} color="text.primary">
              {displayValue}
            </Typography>
          ) : (
            <Link
              key={to}
              component={RouterLink}
              to={to}
              underline="hover"
              color="inherit"
            >
              {displayValue}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsRoutes;
