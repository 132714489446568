import { createAsyncThunk } from "@reduxjs/toolkit";
import { buildQueryParams } from "../../helpers/functions";
import apiRequest from "../../services/auth";
import { getProfile } from "../profile/profileActions";

export const createCampaign = createAsyncThunk(
  "campaign/create",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: "/campaign/create",
      method: "POST",
      data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const getAllCampaign = createAsyncThunk(
  "campaign",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);
export const getFilteredCampaigns = createAsyncThunk(
  "campaign/all",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign/all?${buildQueryParams(data)}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getSearchCampaigns = createAsyncThunk(
  "campaign/search",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign/search?${buildQueryParams(data)}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getGenieCampaigns = createAsyncThunk(
  "campaign/genie",
  async (data = {}, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign/genie`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getCampaign = createAsyncThunk(
  "campaign/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign/get?campaignId=${data}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/update",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/campaign/update/${data.id}`,
      method: "PUT",
      data: data.data,
    }).then(async (response) => {
      return response;
    });
    return res;
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/delete",
  async (id, { dispatch }) => {
    const res = await apiRequest({
      url: `campaign/delete`,
      method: "DELETE",
      data: id,
    }).then((response) => {
      dispatch(getAllCampaign());
      // dispatch(getProfile());
      return response;
    });
    return res;
  }
);

export const getAllPartnerDocuments = createAsyncThunk(
  "getAllPartnerDocuments/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/document/partner`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);

export const getPartnerDocument = createAsyncThunk(
  "getPartnerDocument/get",
  async (data, { dispatch }) => {
    const res = await apiRequest({
      url: `/document/campaign/${data.campaignId}`,
      method: "GET",
      // data,
    }).then((response) => {
      return response;
    });
    return res;
  }
);