import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Paper,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Home from "../../../components/SidebarWithTopBar";
import CommonSelect from "../../../common/CommonSelect";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { getProperties } from "../../../store/property/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import PropertyPopover from "../../../components/PropertyPopover";
import CurrencyInput from "../../../common/CurrencyInput";
import CommonDatePicker from "../../../common/CommonDatePicker";
import { lvrSchema } from "./schema";
import { Validate } from "../../../helpers/functions";
import PercentageInput from "../../../common/PercentageInput";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoanRepaymentChart from "../../../components/Chart/LoanRepaymentChart";
// import Breadcrumbs from "../../../components/BreadCrumbs/BreadCrumbs"
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: "100%",
    maxWidth: "100%",
    overflowX: "scroll",
  },
});
const summaryViewTable = [
  "Property Type",
  "Initial Investment",
  "Loan Amount",
  "Interest Rate",
  "Interet Type",
  "Capital Growth",
  "Rental Growth",
  "Weekly Rent",
  "Annual Deductions",
  "Year Purchased",
  "Investment Period",
];

const investmentTable = [
  "Years",
  "Payment Due Date",
  "Principal Payable",
  "Interest Payable",
  "Total Repayment",
  "Outstanding Loan",
];

const investmentTableObjects = investmentTable.map((item) => {
  return {
    value: item,
    label: item,
  };
});

const summaryViewTableObjects = summaryViewTable.map((item) => {
  return {
    value: item,
    label: item,
  };
});

const tableTitlesIncome = ["	Property 1	", "	Property 2	", "	Property 3	"];
// const tabileYears = ["	1	", "2", "3	"];
const tabileYears = Array.from({ length: 30 }, (_, index) =>
  (index + 1).toString()
);
const tableTitlesTotal = ["	Total "];

const LoanRepaymentCalculator = () => {
  const classes = useStyles();
  const [selectedInvestment, setSelectedInvestment] = useState([]);
  const [data, setData] = useState({
    propertyType: "",
    initialInvestment: null,
    loanAmount: null,
    interestRate: null,
    interestType: "Fixed",
    capitalGrowth: null,
    rentalGrowth: null,
    weeklyRent: null,
    annualDeductions: null,
    depreciation: null,
    purchaseDate: null,
    investmentPeriod: null,

    firstPaymentDate: new Date("2021-10-03"),
    paymentFrequency: "Monthly",
    loanTerms: null,
    finalPayment: null,
    repaymentAmount: null,
    totalInterestPaid: null,
    totalPrincipal: null,
    totalPayments: null,
  });

  const clearTable = () => {
    setSelectedInvestment();
  };
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.properties.properties);
  const [selectedProperty, setSelectedProperty] = useState("");
  const { PROPERTY_TYPES } = useSelector((state) => state.common.data);
  const [error, setError] = useState({
    path: null,
  });
  useEffect(() => {}, []);

  useEffect(() => {
    if (properties.length > 0 && selectedProperty === "") {
      const property = properties.find(
        (prop) => !prop.property.personalProperty
      );
      if (property) {
        setSelectedProperty(property?._id || "");
      }
    }
  }, [properties, selectedProperty]);

  useEffect(() => {
    const property = properties.find((prop) => prop._id === selectedProperty);

    if (property) {
      const dateObject = new Date(property.purchases.purchaseDate);
      const fullYear = dateObject.getFullYear();
      const formattedNumber = Number(fullYear).toFixed(0);
      setData({
        propertyType: property.property.type || "",
        initialInvestment: property.purchases.purchasePrice || null,
        loanAmount: property.purchases.loanAmount || null,
        interestRate: property.financials.loans[0]?.interestRate || null,
        interestType: "Fixed",
        capitalGrowth: null,
        rentalGrowth: null,
        weeklyRent: null,
        annualDeductions: null,
        purchaseDate: Number(formattedNumber),
        investmentPeriod: null,
        depreciation: null,
        firstPaymentDate: new Date(),
        paymentFrequency: "Monthly",
        loanTerms: null,
        finalPayment: null,
      });
    }
  }, [properties, selectedProperty]);

  const calculateData = async () => {
    const {
      initialInvestment,
      loanAmount,
      interestRate,
      interestType,
      capitalGrowth,
      rentalGrowth,
      weeklyRent,
      annualDeductions,
      purchaseDate,
      investmentPeriod,
      depreciation,
      firstPaymentDate,
      paymentFrequency,
      loanTerms,
      finalPayment,
    } = data;

    let Result = [];
    try {
      let validationError = await Validate(lvrSchema, data);
      if (validationError) {
        setError(validationError);
        return;
      } else {
        setError({
          path: null,
        });
      }

      const monthlyInterestRate = interestRate / 12 / 100;
      const numberOfPayments = loanTerms * 12;
      const monthlyPayment =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
      const monthlyPay =
        (loanAmount * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -(numberOfPayments * 12)));

      let outstandingLoanBalance = loanAmount;
      const amortizationSchedule = [];
      const chartData = [];

      const dueDate = new Date(firstPaymentDate);

      for (let month = 1; month <= numberOfPayments; month++) {
        const interestPayment = outstandingLoanBalance * monthlyInterestRate;
        const principalPayment = monthlyPayment - interestPayment;
        outstandingLoanBalance -= principalPayment;

        dueDate.setMonth(dueDate.getMonth() + 1);
        const paymentDetails = {
          month: month,
          dueDate: dueDate.toISOString().slice(0, 10),
          principalPayable: principalPayment.toFixed(2),
          interestPayable: interestPayment.toFixed(2),
          totalRepayment: monthlyPayment.toFixed(2),
          outstandingLoanBalance: outstandingLoanBalance.toFixed(2),
        };

        if (month % 12 === 0) {
          amortizationSchedule.push(paymentDetails);
        }
      }
      setSelectedInvestment(amortizationSchedule);

      const test = {
        monthlyPayment: monthlyPayment.toFixed(2),
        totalInterest: (
          monthlyPayment.toFixed(2) * numberOfPayments -
          loanAmount
        ).toFixed(2),
      };

      const currencyStringToNumber = (currencyString) => {
        if (typeof currencyString !== "string") {
          throw new Error("Invalid currency string");
        }
        return parseFloat(currencyString.replace(/[^\d.]/g, ""));
      };

      const numberToCurrencyString = (number) => {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(number);
      };
      setData({
        ...data,
        repaymentAmount: test.monthlyPayment,
        totalInterestPaid: currencyStringToNumber(test.totalInterest),
        totalPrincipal: currencyStringToNumber(test.totalInterest) + loanAmount,
        totalPayments: loanTerms,
      });

      return amortizationSchedule;
    } catch (error) {
      console.error("Error occurred during calculation:", error);
    }
  };

  const formatTotalValue = (total) => {
    let formattedTotal = "";
    const isValidTotal = typeof total === "number" && !isNaN(total);

    if (isValidTotal) {
      if (total >= 1000000000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total / 1000000000) + " B";
      } else if (total >= 1000000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(total / 1000000) + " M";
      } else if (total >= 1000) {
        formattedTotal =
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(total / 1000) + " K";
      } else {
        formattedTotal = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(total);
      }
    }
    return formattedTotal;
  };

  const formatCurrency = (value) => {
    const numericValue = typeof value === "number" ? value : parseFloat(value);
    if (isNaN(numericValue)) {
      console.error("Invalid numeric value:", value);
      return "";
    }

    return numericValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <>
      <Home>
        {/* <Breadcrumbs /> */}
        <Stack mt={9} px={1} spacing={1}>
          <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "gray" }}>
            What if analysis
          </Typography>
          <Stack
            direction={"row"}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack spacing={1}>
              <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
                Loan Repayment Calculator
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  fontWeight: 500,
                  color: "#B67DC4",
                  fontSize: "13px",
                  fontStyle: "italic",
                }}
              >
                Calculate your mortgage repayments
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Grid container>
          <Grid item md={6} xs={12}>
            <Box>
              <Stack
                mt={2}
                px={1}
                gap={"30px"}
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                width={"100%"}
              >
                <Typography>Property</Typography>
                <Stack direction={"row"}>
                  <Stack>
                    <PropertyPopover
                      properties={properties}
                      propertyTypes={PROPERTY_TYPES}
                      setSelectedProperties={(properties) => {
                        if (properties.length > 0) {
                          setSelectedProperty(
                            properties[properties.length - 1]
                          );
                        }
                      }}
                      selectedProperties={[selectedProperty]}
                      buttonLabel={
                        properties.find((p) => p._id == selectedProperty)
                          ?.property?.address.fullAddress || ""
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Box>

            <Grid
              container
              spacing={3}
              mt={3}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Grid item md={4} xs={6}>
                <Typography>Loan Amount</Typography>
              </Grid>
              <Grid item md={4} xs={6}>
                <CurrencyInput
                  placeholder="$ Loan Amount"
                  value={data.loanAmount}
                  sx={{ maxWidth: "180px", fontSize: "14px !important" }}
                  onChange={(newValue) => {
                    const numericValue = parseFloat(newValue);
                    setData({
                      ...data,
                      loanAmount: isNaN(numericValue) ? null : numericValue,
                    });
                  }}
                />
                <Stack>
                  {error.path === "loanAmount" && (
                    <Typography
                      style={{
                        color: "purple",
                        fontSize: "0.75rem",
                        fontWeight: "600",
                        marginTop: "4px",
                      }}
                    >
                      {error.message}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Stack mt={1}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item md={4} xs={6}>
                  <Typography>Interest Rate </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <PercentageInput
                    placeholder="% Interest Rate"
                    sx={{ maxWidth: "180px", fontSize: "14px !important" }}
                    value={data.interestRate}
                    onChange={(newValue) => {
                      const numericValue = parseFloat(newValue);
                      setData({
                        ...data,
                        interestRate: isNaN(numericValue) ? null : numericValue,
                      });
                    }}
                  />
                  <Stack>
                    {error.path === "interestRate" && (
                      <Typography
                        style={{
                          color: "purple",
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Stack mt={1}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item md={4} xs={6}>
                  <Typography>First Payment Date</Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <CommonDatePicker
                    value={data.firstPaymentDate}
                    sx={{ maxWidth: "180px", fontSize: "14px !important" }}
                    onChange={(event) => {
                      const updatedStartDate = event;
                      setData({
                        ...data,
                        firstPaymentDate: updatedStartDate,
                      });
                    }}
                  />
                  <Stack>
                    {error.path === "firstPaymentDate" && (
                      <Typography
                        style={{
                          color: "purple",
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Stack mt={1} sx={{ marginTop: "-0.5em !important" }}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item md={4} xs={6}>
                  <Typography>Payment Frequency</Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "1.5rem",
                        minWidth: "140px",
                        fontSize: "14px !important",
                      },
                    }}
                    InputProps={{
                      sx: {
                        borderTopLeftRadius: "0rem",
                        borderBottomLeftRadius: "0rem",
                        borderRadius: 5,
                        boxShadow: 3,
                        marginTop: "unset",
                      },
                    }}
                    size="small"
                    margin="normal"
                    disabled
                    variant="outlined"
                    type="text"
                    name="paymentFrequency"
                    placeholder="Payment Frequency"
                    value={data.paymentFrequency}
                    onChange={(event) => {
                      const value = event.target.value;
                      setData({ ...data, paymentFrequency: value });
                    }}
                    inputProps={{
                      style: { fontSize: "14px" },
                      marginTop: "unset",
                    }}
                  />
                  <Stack>
                    {error.path === "paymentFrequency" && (
                      <Typography
                        style={{
                          color: "purple",
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Stack mt={1} sx={{ marginTop: "-1em !important" }}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item md={4} xs={6}>
                  <Typography>Loan term (years)</Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "1.5rem",
                        marginTop: "1px !important",
                        minWidth: "135px",
                        fontSize: "14px !important",
                      },
                    }}
                    InputProps={{
                      sx: {
                        borderTopLeftRadius: "0rem",
                        borderBottomLeftRadius: "0rem",
                        marginTop: "1px !important",
                        borderRadius: 5,
                        boxShadow: 3,
                      },
                    }}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    type="number"
                    name=" loanTerms"
                    placeholder="Loan Term"
                    value={data.loanTerms}
                    onChange={(event) => {
                      const value = event.target.value;
                      setData({ ...data, loanTerms: event.target.value });
                    }}
                    inputProps={{
                      style: { fontSize: "14px" },
                    }}
                  />
                  <Stack>
                    {error.path === "loanTerms" && (
                      <Typography
                        style={{
                          color: "purple",
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Stack mt={1} sx={{ marginTop: "-1.4em !important" }}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item md={4} xs={6} sx={{ marginTop: "1.5em !important" }}>
                  <Typography>Final Payment/ Baloon</Typography>
                </Grid>
                <Grid item md={4} xs={6} sx={{ marginTop: "1.5em !important" }}>
                  <CurrencyInput
                    placeholder="$ Final Payment"
                    value={data.finalPayment}
                    sx={{
                      maxWidth: "180px",
                      fontSize: "14px !important",
                    }}
                    onChange={(newValue) => {
                      const numericValue = parseFloat(newValue);
                      setData({
                        ...data,
                        finalPayment: isNaN(numericValue) ? null : numericValue,
                      });
                    }}
                  />
                  <Stack>
                    {error.path === "finalPayment" && (
                      <Typography
                        style={{
                          color: "purple",
                          fontSize: "0.75rem",
                          fontWeight: "600",
                          marginTop: "4px",
                        }}
                      >
                        {error.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Grid item mt xs={12} md={12}>
              <Stack
                mt={3}
                mb={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => calculateData()}
                  sx={{
                    border: "1px solid lightgray",
                    width: "fit-content",

                    fontWeight: 600,
                    color: "#fff",
                    bgcolor: "#24003D !important",
                    border: "1px solid #24003D !important",
                    textTransform: "none",
                    borderRadius: "5px",
                    px: 3,
                    boxShadow:
                      "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                    transition: "box-shadow 0.3s ease",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  Calculate
                </Button>

                <Button
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    setData({
                      propertyType: "",
                      initialInvestment: "",
                      loanAmount: "",
                      interestRate: "",
                      interestType: "Fixed",
                      capitalGrowth: "",
                      rentalGrowth: "",
                      weeklyRent: "",
                      annualDeductions: "",
                      depreciation: "",
                      purchaseDate: "",
                      investmentPeriod: "",
                      firstPaymentDate: "",
                      paymentFrequency: "Monthly",
                      totalPayments: "",
                      loanTerms: "",
                      finalPayment: "",
                    });
                    clearTable();
                  }}
                  sx={{
                    border: "1px solid lightgray",
                    width: "fit-content",
                    marginLeft: "1em",
                    fontWeight: 600,
                    letterSpacing: 0,
                    color: "#000",
                    bgcolor: "#fff !important",
                    textTransform: "none",
                    border: "2px solid #24003D !important",
                    borderRadius: "8px",
                  }}
                >
                  Clear All
                </Button>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {/* <Stack gap={"5px"}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#0f2e5a",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  Download
                </Typography>
                <Stack>
                  <CommonSelect
                    style={{ width: "200px" }}
                    value="selectedOptionValue"
                  />
                </Stack>
              </Stack> */}
            </Box>
            <Stack mt={5}>
              <Box mt={5}>
                <LoanRepaymentChart
                  data={selectedInvestment}
                  loanAmount={data.loanAmount}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Stack mt={3}>
          <Typography sx={{ fontWeight: 700 }}>Results</Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Stack mt={1}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6}>
                  <Typography textAlign={{ md: "end", xs: "start" }}>Repayment Amount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <CurrencyInput
                    placeholder="$ Repayment Amount"
                    value={data.repaymentAmount}
                    sx={{ maxWidth: "180px", fontSize: "14px !important" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          <Grid item md={6} xs={12}>
            <Stack>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item md={3} xs={6}>
                  <Typography>Total Interest Paid</Typography>
                </Grid>
                <Grid item xs={6}>
                  <CurrencyInput
                    placeholder="$ Total Interest Paid"
                    value={data.totalInterestPaid}
                    sx={{ maxWidth: "180px", fontSize: "14px !important" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Stack>
              <Grid container spacing={2} mt={1} alignItems={"center"}>
                <Grid item xs={6}>
                  <Typography textAlign={{ md: "end", xs: "start" }}>
                    Total Principal + Interest Paid
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <CurrencyInput
                    placeholder="$ Total Principal"
                    value={data.totalPrincipal}
                    sx={{ maxWidth: "180px", fontSize: "14px !important" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          <Grid item md={6} xs={12}>
            <Stack mt={1}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item md={3} xs={6}>
                  <Typography>Total Number of Payments</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        height: "1.5rem",
                        minWidth: "135px",
                        fontSize: "14px !important",
                      },
                    }}
                    InputProps={{
                      sx: {
                        borderTopLeftRadius: "0rem",
                        borderBottomLeftRadius: "0rem",
                        borderRadius: 5,
                        boxShadow: 3,
                      },
                    }}
                    size="small"
                    margin="normal"
                    variant="outlined"
                    type="text"
                    name="totalPayments"
                    placeholder="Total No of Payments"
                    value={data.totalPayments}
                    inputProps={{
                      style: { fontSize: "14px" },
                    }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>

        {/* <Grid
          px={1}
          container
          justifyContent={"center"}
          spacing={{ xs: 0, md: 2 }}
          mt={{ xs: 8, md: 4 }}
        >
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  {summaryViewTableObjects.map((title, index) => (
                    <TableCell key={index} align="left">
                      <Stack
                        minWidth={"maxWidth"}
                        width={"100%"}
                        gap={3}
                        direction={"row"}
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Stack className="table-header">
                          <Typography
                            // width={"100px"}
                            align="center"
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                          >
                            {title?.value}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {tableTitlesIncome.map((item, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {item}
                          </TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">- </TableCell>
                          <TableCell align="center"> -</TableCell>
                          <TableCell align="center"> -</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center"> -</TableCell>
                          <TableCell align="center">- </TableCell>
                          <TableCell align="center">- </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid> */}

        <Grid
          px={1}
          container
          justifyContent={"center"}
          spacing={{ xs: 0, md: 2 }}
          mt={{ xs: 8, md: 4 }}
        >
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  {investmentTableObjects.map((title, index) => (
                    <TableCell
                      sx={{ backgroundColor: "#24003D" }}
                      key={index}
                      align="left"
                    >
                      <Stack
                        minWidth={"maxWidth"}
                        width={"100%"}
                        gap={3}
                        direction={"row"}
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Stack className="table-header">
                          <Typography
                            // width={"100px"}
                            align="center"
                            sx={{ fontWeight: "bold", color: "#fff" }}
                          >
                            {title?.value}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {selectedInvestment !== undefined &&
                    selectedInvestment.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#d6d6d6",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#d6d6d6" }}
                        >
                          {new Date(item.dueDate)
                            .toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/\//g, "-")}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.principalPayable)}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.interestPayable)}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.totalRepayment)}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.outstandingLoanBalance)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Home>
    </>
  );
};

export default LoanRepaymentCalculator;
