import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  createNote,
  getAllNote,
  getNote,
  updateNote,
  deleteNote,
} from "./noteActions";

const noteSlice = createSlice({
  name: "note",
  initialState: {
    loading: false,
    notes: [],
    note: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.note = action?.payload?.data;
        }
      })
      .addCase(createNote.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllNote.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notes = action?.payload?.data;
        }
      })
      .addCase(getAllNote.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNote.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.notes = action?.payload?.data;
        }
      })
      .addCase(getNote.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.note = action?.payload?.data;
        }
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteNote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.note = action?.payload?.data;
        }
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default noteSlice.reducer;
