import { createSlice } from "@reduxjs/toolkit";
import { cookies } from "../../services/auth";
import {
  createCampaign,
  getAllCampaign,
  getCampaign,
  updateCampaign,
  deleteCampaign,
  getFilteredCampaigns,
  getSearchCampaigns,
  getGenieCampaigns,
  getAllPartnerDocuments,
  getPartnerDocument,
} from "./campaignActions";

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    loading: false,
    campaigns: [],
    genieCampaigns: [],
    products: [],
    allDocuments: [],
    statements: [],
    campaign: {},
    currentRoute: "active"
  },
  reducers: {
    setCurrentRoute: (state, action) => {
      state.currentRoute = "active"
    }
  },

  extraReducers: (builder) => {
    // you can mutate state directly, since it is using immer behind the scenes
    builder
      .addCase(createCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaign = action?.payload?.data;
          state.currentRoute = "draft"
        }
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaigns = action?.payload?.data;
        }
      })
      .addCase(getAllCampaign.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getFilteredCampaigns.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getFilteredCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaigns = action?.payload?.data;
        }
      })
      .addCase(getFilteredCampaigns.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSearchCampaigns.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSearchCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data?.allProducts) {
          state.products = action?.payload?.data.allProducts;
        }
      })
      .addCase(getSearchCampaigns.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getGenieCampaigns.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getGenieCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.genieCampaigns = action?.payload?.data;
        }
      })
      .addCase(getGenieCampaigns.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaign = action?.payload?.data;
        }
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaign = action?.payload?.data;
          state.currentRoute = "draft"
        }
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllPartnerDocuments.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllPartnerDocuments.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.allDocuments = action?.payload?.data;
        }
      })
      .addCase(getAllPartnerDocuments.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPartnerDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPartnerDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.statements = action?.payload?.data;
        }
      })
      .addCase(getPartnerDocument.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.data) {
          state.campaign = action?.payload?.data;
        }
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setCurrentRoute } = campaignSlice.actions;

export default campaignSlice.reducer;
