import React, { useEffect, useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate, Link } from "react-router-dom";
// import Logo from "../../assests/mainLogo.png";
// import Logo from "../../assests/logo/crop/color_logo.png";
import Logo from "../../assests/logo/New-crop/color_logo.png";
// import Drawer_Logo from "../../assests/logo/crop/white_logo_transparent.png";
import Drawer_Logo from "../../assests/logo/New-crop/white_transparent_logo.png";
// import { ReactComponent as Logo } from "../../assests/logo/svg/color_logo_transparent.svg";
import { Stack, Drawer, List, ListItem } from "@mui/material";
import { cookies } from "../../services/auth";
import routes from "../../routes/RoutesConstants";
import FullWidthModal from "../Modal";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "react-responsive";
const pages = ["About Us", "Features", "Pricing", "Resources", "Contact Us"];

const submenuData = {
  about: [
    "Our Story",
    "Contact Us",
    "Careers",
    "Request a feature",
    "Referral Program",
  ],
  // features: [
  //   "Rental Accounting",
  //   "Property Maintenance",
  //   "Dashboard & Analytics",
  //   "Tax Reporting",
  //   "File Storage",
  //   "Communication Central",
  // ],
  // pricing: ["Property Investor", "Property Agent"],
  resources: ["FAQ's", "Calculators"],
  demo: ["Request a demo"],
};

function Header() {
  const [token, setToken] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const headerRef = useRef(null);
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState(null);
  const [hoveredSubMenu, setHoveredSubMenu] = useState(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = token ? () => navigate("/dashboard") : handleOpenModal;

  const check = async () => {
    const tok = await cookies.get("SID");
    if (tok) {
      setToken(true);
    }
  };

  useEffect(() => {
    check();
  }, []);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenSubmenu = (event, page) => {
    setSubmenuAnchorEl(event.currentTarget);
    setHoveredSubMenu(page && page.toLowerCase());
  };

  const handleCloseMenu = () => {
    setMainMenuAnchorEl(null);
    setSubmenuAnchorEl(null);
    setHoveredSubMenu(null);
  };

  const handleNavigation = (page) => {
    const formattedPage = page
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "")
      .replace(/'/g, "");
    const routePath = `/${formattedPage}`;
    const isValidRoute = routes.some((route) => route.path === routePath);
    if (isValidRoute) {
      navigate(routePath);
      handleCloseMenu();
    } else if (routePath === "/contact-us") {
      navigate("/contact");
      handleCloseMenu();
    } else if (routePath === "/about-us") {
      navigate("/about");
      handleCloseMenu();
    }
  };
  return (
    <AppBar position="static" sx={{ background: "#fff", boxShadow: 0 }}>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ flexGrow: 1 }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, display: { md: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>
          <img
            onClick={() => {
              navigate("/");
            }}
            src={Logo}
            alt="logo"
            height={60}
            width={isMobile ? 200 : "auto"}
            style={{ cursor: "pointer" }}
          />
          {/* <Typography
            component={Link}
            to="/"
            sx={{
              color: "#24003D",
              fontWeight: 600,
              fontSize: 20,
              cursor: "pointer",
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            InvestPulse
          </Typography> */}
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            gap: 3,
            justifyContent: "left",
          }}
        >
          {pages.map((page) => (
            <div key={page}>
              <Button
                sx={{
                  my: 2,
                  color: page === "Resources" ? "#24003D" : "#24003D",
                  display: "block",
                  textTransform: "none",
                  fontWeight: "500",
                  letterSpacing: 0,
                  fontSize: "20px",
                  cursor: page === "Resources" ? "pointer" : "pointer",
                  "&:hover": {
                    backgroundColor:
                      page === "Resources" ? "#24003D" : "#24003D",
                    color: page === "Resources" ? "#fff" : "#fff",
                  },
                  zIndex: 8000,
                }}
                onMouseOver={(e) => handleOpenSubmenu(e, page)}
                onClick={
                  page === "Resources" ? null : () => handleNavigation(page)
                }
                data-menu-button
              >
                {page}
              </Button>

              {submenuData[page.toLowerCase()] && (
                <Menu
                  id={`${page.toLowerCase()}-menu`}
                  anchorEl={submenuAnchorEl}
                  open={Boolean(hoveredSubMenu === page.toLowerCase())}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: { background: "#24003D", color: "#fff" },
                  }}
                >
                  {submenuData[page.toLowerCase()].map((submenuItem) => (
                    <MenuItem
                      key={submenuItem}
                      onClick={() => handleNavigation(submenuItem)}
                    >
                      {submenuItem}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div>
          ))}
        </Box>

        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" }, gap: 1 }}>
          <Button
            onClick={handleClick}
            sx={{
              border: "1px solid lightgray",
              fontWeight: 600,
              letterSpacing: 0,
              color: "#fff",
              bgcolor: "#24003D !important",
              textTransform: "none",
              border: "2px solid #fff !important",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#4f306d",
              },
            }}
            variant="outlined"
          >
            {token ? "Dashboard" : "Login"}
          </Button>
          <div style={{ zIndex: 9999 }}>
            <FullWidthModal open={isModalOpen} onClose={handleCloseModal} />
          </div>
          {!token ? (
            <Button
              onClick={() => handleClick()}
              sx={{
                border: "1px solid lightgray",
                fontWeight: 500,
                color: "#fff",
                letterSpacing: 0,
                bgcolor: "#24003D !important",
                textTransform: "none",
                border: "2px solid #fff !important",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#4f306d",
                },
              }}
              variant="outlined"
            >
              Get Started
            </Button>
          ) : null}
        </Box>
      </Toolbar>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            color: "#fff",
            background: "#24003D",
          },
        }}
      >
        <Stack
          onClick={() => {
            navigate("/");
          }}
          flexDirection={"row"}
          display={"flex"}
          justifyContent={"space-around"}
          mt={2}
          sx={{ cursor: "pointer" }}
        >
          <img
            src={Drawer_Logo}
            alt="Drawer_Logo"
            height={"auto"}
            width={130}
            style={{ marginLeft: 10 }}
          />
          {/* <Typography
            component={Link}
            to="/"
            sx={{
              color: "#fff",
              fontWeight: 600,
              fontSize: 20,
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            InvestPulse
          </Typography> */}
          <button
            onClick={handleDrawerClose}
            style={{ background: "unset", color: "#fff" }}
          >
            <CloseIcon sx={{ width: "fit-content", color: "#fff" }} />
          </button>
        </Stack>
        <List>
          {pages.map((page) => (
            <div key={page} style={{ paddingLeft: 15 }}>
              <Button
                sx={{
                  my: 2,
                  color: page === "Resources" ? "#fff" : "#fff",
                  display: "block",
                  textTransform: "none",
                  fontWeight: "500",
                  letterSpacing: 0,
                  fontSize: "20px",
                  cursor: page === "Resources" ? "pointer" : "pointer",
                  "&:hover": {
                    backgroundColor:
                      page === "Resources" ? "#24003D" : "#24003D",
                    color: page === "Resources" ? "#fff" : "#fff",
                  },
                  zIndex: 8000,
                }}
                onMouseOver={(e) => handleOpenSubmenu(e, page)}
                onClick={
                  page === "Resources" ? null : () => handleNavigation(page)
                }
                data-menu-button
              >
                {page}
              </Button>

              {submenuData[page.toLowerCase()] && (
                <Menu
                  id={`${page.toLowerCase()}-menu`}
                  anchorEl={submenuAnchorEl}
                  open={Boolean(hoveredSubMenu === page.toLowerCase())}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: { background: "#24003D", color: "#fff" },
                  }}
                >
                  {submenuData[page.toLowerCase()].map((submenuItem) => (
                    <MenuItem
                      key={submenuItem}
                      onClick={() => handleNavigation(submenuItem)}
                    >
                      {submenuItem}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div>
          ))}
        </List>

        <Box px={3}>
          <Button
            onClick={handleClick}
            sx={{
              border: "1px solid lightgray",
              fontWeight: 600,
              letterSpacing: 0,
              color: "#fff",
              bgcolor: "#24003D !important",
              textTransform: "none",
              border: "2px solid #fff !important",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#4f306d",
              },
              mr: 2,
            }}
            variant="outlined"
          >
            {token ? "Dashboard" : "Login"}
          </Button>
          <FullWidthModal open={isModalOpen} onClose={handleCloseModal} />
          {!token && (
            <Button
              onClick={() => navigate("/register")}
              sx={{
                border: "1px solid lightgray",
                fontWeight: 500,
                color: "#fff",
                letterSpacing: 0,
                bgcolor: "#24003D !important",
                textTransform: "none",
                border: "2px solid #fff !important",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#4f306d",
                },
              }}
              variant="outlined"
            >
              Get Started
            </Button>
          )}
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default Header;
