import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Paper,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Home from "../../../components/SidebarWithTopBar";
import CommonSelect from "../../../common/CommonSelect";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import { getProperties } from "../../../store/property/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import PropertyPopover from "../../../components/PropertyPopover";
import CurrencyInput from "../../../common/CurrencyInput";
import CommonDatePicker from "../../../common/CommonDatePicker";
import { lvrSchema } from "./schema";
import { Validate } from "../../../helpers/functions";
import PercentageInput from "../../../common/PercentageInput";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: "100%",
    maxWidth: "100%",
    overflowX: "scroll",
  },
});
const summaryViewTable = [
  "Property Type",
  "Initial Investment",
  "Loan Amount",
  "Interest Rate",
  "Interet Type",
  "Capital Growth",
  "Rental Growth",
  "Weekly Rent",
  "Annual Deductions",
  "Year Purchased",
  "Investment Period",
];

const investmentTable = [
  " ",
  "Year",
  "Total Property Value",
  "Loan Value",
  "Equity",
  "Capital Growth",
  "LVR",
];

const investmentTableObjects = investmentTable.map((item) => {
  return {
    value: item,
    label: item,
  };
});

const summaryViewTableObjects = summaryViewTable.map((item) => {
  return {
    value: item,
    label: item,
  };
});

const tableTitlesIncome = ["	Property 1	", "	Property 2	", "	Property 3	"];
// const tabileYears = ["	1	", "2", "3	"];
const tabileYears = Array.from({ length: 30 }, (_, index) =>
  (index + 1).toString()
);
const tableTitlesTotal = ["	Total "];

const LVRProjections = () => {
  const classes = useStyles();
  const [selectedInvestment, setSelectedInvestment] = useState([]);
  const [data, setData] = useState({
    propertyType: "",
    initialInvestment: null,
    loanAmount: null,
    interestRate: null,
    interestType: "Fixed",
    capitalGrowth: null,
    rentalGrowth: null,
    weeklyRent: null,
    annualDeductions: null,
    depreciation: null,
    purchaseDate: null,
    investmentPeriod: null,
  });

  const clearTable = () => {
    setSelectedInvestment();
  };
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.properties.properties);
  const [selectedProperty, setSelectedProperty] = useState("");
  const { PROPERTY_TYPES } = useSelector((state) => state.common.data);
  const [error, setError] = useState({
    path: null,
  });
  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (properties.length > 0 && selectedProperty === "") {
      const property = properties.find(
        (prop) => !prop.property.personalProperty
      );
      if (property) {
        setSelectedProperty(property?._id || "");
      }
    }
  }, [properties, selectedProperty]);

  useEffect(() => {
    const property = properties.find((prop) => prop._id === selectedProperty);

    if (property) {
      const dateObject = new Date(property.purchases.purchaseDate);
      const fullYear = dateObject.getFullYear();
      const formattedNumber = Number(fullYear).toFixed(0);
      setData({
        propertyType: property.property.type || "",
        initialInvestment: property.purchases.purchasePrice || null,
        loanAmount: property.purchases.loanAmount || null,
        interestRate: property.financials.loans[0]?.interestRate || null,
        interestType: "Fixed",
        capitalGrowth: null,
        rentalGrowth: null,
        weeklyRent: null,
        annualDeductions: null,
        purchaseDate: Number(formattedNumber),
        investmentPeriod: null,
        depreciation: null,
      });
    } 
  }, [properties, selectedProperty]);

  const calculateData = async () => {
    const {
      initialInvestment,
      loanAmount,
      interestRate,
      interestType,
      capitalGrowth,
      rentalGrowth,
      weeklyRent,
      annualDeductions,
      purchaseDate,
      investmentPeriod,
      depreciation,
    } = data;

    let Result = [];

    try {
      let validationError = await Validate(lvrSchema, data);
      if (validationError) {
        setError(validationError);
        return;
      } else {
        setError({
          path: null,
        });
      }

      Result.push({ k: "Initial", l: purchaseDate, m: initialInvestment });

      // Generate entries for subsequent years
      // for (let year = 1; year <= investmentPeriod; year++, Result) {
      //   let mm =
      //     Result[year - 1].m + Result[year - 1].m * (capitalGrowth / 100);
      //   let mn = Result[year - 1]?.m || initialInvestment;
      //   let qn = ((loanAmount / mm) * 100).toFixed();
      //   let qr =
      //     Result[year - 1].r + Result[year - 1].r * (rentalGrowth / 100) ||
      //     weeklyRent;
      //   let qt =
      //     Result[year - 1].t + Result[year - 1].t * (rentalGrowth / 100) ||
      //     annualDeductions;
      //   let qs = (loanAmount * interestRate) / 100;
      //   let qu = qs - qr + qt;
      //   let qw = ((qr / mn) * 100).toFixed(2);
      //   let qx = (qr - qt).toFixed();
      //   let qy = ((qx / mn) * 100).toFixed(2);
      //   let newEntry = {
      //     k: year,
      //     l: purchaseDate + year,
      //     m: mm,
      //     n: loanAmount,
      //     o: (mm - loanAmount).toFixed(),
      //     p: (mm - mn).toFixed(),
      //     q: qn,
      //     r: qr,
      //     // s: qs,
      //     // t: qt,
      //     // u: qu,
      //     // v: depreciation,
      //     // w: qw,
      //     // x: qx,
      //     // y: qy,
      //   };

      //   Result.push(newEntry);
      // }

      for (let year = 1; year <= investmentPeriod; year++, Result) {
        let mm =
          Result[year - 1].m + Result[year - 1].m * (capitalGrowth / 100);
        let mn = Result[year - 1]?.m || initialInvestment;
        let qn = Number((loanAmount / mm) * 100).toFixed();
        let qr =
          Result[year - 1].r + Result[year - 1].r * (rentalGrowth / 100) ||
          weeklyRent;
        let qt =
          Result[year - 1].t + Result[year - 1].t * (rentalGrowth / 100) ||
          annualDeductions;
        let qs = (loanAmount * interestRate) / 100;
        let qu = qs - qr + qt;
        let qw = Number(((qr / mn) * 100).toFixed(2));
        let qx = Number((qr - qt).toFixed());
        let qy = Number(((qx / mn) * 100).toFixed(2));
        let newEntry = {
          k: year,
          l: purchaseDate + year,
          m: mm,
          n: loanAmount,
          o: Number((mm - loanAmount).toFixed()),
          p: Number((mm - mn).toFixed()),
          q: Number(qn).toFixed(),
          r: qr,
          // s: qs,
          // t: qt,
          // u: qu,
          // v: depreciation,
          // w: qw,
          // x: qx,
          // y: qy,
        };

        Result.push(newEntry);
      }
      setSelectedInvestment(Result);
    } catch (error) {
      console.error("Error occurred during validation:", error);
    }
    // Initial entry
  };

  const formatCurrency = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <>
      <Home>
        <Stack mt={9} px={1} spacing={1}>
          <Typography sx={{ fontSize: "12px", fontWeight: 500, color: "gray" }}>
            What if analysis
          </Typography>
          <Stack
            direction={"row"}
            width={"100%"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack spacing={1}>
              <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
                LVR Projections
              </Typography>
              <Typography
                sx={{
                  cursor: "pointer",
                  fontWeight: 500,
                  color: "#B67DC4",
                  fontSize: "13px",
                  fontStyle: "italic",
                }}
              >
                Consolidated Loan Value Ratios over investment period
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          mt={2}
          px={1}
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          width={"100%"}
        >
          <Stack direction={"row"}>
            <Stack>
              <PropertyPopover
                properties={properties}
                propertyTypes={PROPERTY_TYPES}
                setSelectedProperties={(properties) => {
                  if (properties.length > 0) {
                    setSelectedProperty(properties[properties.length - 1]);
                  }
                }}
                selectedProperties={[selectedProperty]}
                buttonLabel={
                  properties.find((p) => p._id == selectedProperty)?.property
                    ?.address.fullAddress || ""
                }
              />
            </Stack>
          </Stack>
          {/* <Stack>
            <Typography
              sx={{
                textAlign:"center",
                color: "#0f2e5a",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Download
            </Typography>
            <Stack>
              <CommonSelect
                style={{ width: "200px" }}
                value="selectedOptionValue"
              />
            </Stack>
          </Stack> */}
        </Stack>

        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Property Type
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "1.5rem",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderTopLeftRadius: "0rem",
                      borderBottomLeftRadius: "0rem",
                      // marginTop: 1,
                      borderRadius: 5,
                      boxShadow: 3,
                    },
                  }}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  name="propertyType"
                  placeholder="Property Type"
                  fullWidth
                  value={
                    PROPERTY_TYPES.find(
                      (type) => type.value === data.propertyType
                    )?.label || ""
                  }
                  inputProps={{
                    style: { fontSize: "13px" },
                  }}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Initial Investment
              </Typography>
              <CurrencyInput
                placeholder="$"
                value={data.initialInvestment}
                // onChange={(data) => setInvestment(data)}
                sx={{ maxWidth: "350px" }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Loan Amount
              </Typography>
              <CurrencyInput
                placeholder="$"
                value={data.loanAmount}
                // onChange={(data) => setInvestment(data)}
                sx={{ maxWidth: "350px" }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Interest Rate
              </Typography>
              <PercentageInput
                placeholder="%"
                sx={{
                  maxWidth: "350px",
                }}
                // style={{fontSize:'30px'}}
                value={data.interestRate}
                onChange={(newValue) => {
                  const numericValue = parseFloat(newValue);
                  setData({
                    ...data,
                    interestRate: isNaN(numericValue) ? null : numericValue,
                  });
                }}
              />
            </Stack>
            <Stack>
              {error.path === "interestRate" && (
                <Typography
                  style={{
                    color: "purple",
                    fontSize: "0.75rem",
                    fontWeight: "600",
                    marginTop: "4px",
                  }}
                >
                  {error.message}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Interest Type
                <Tooltip
                  title="For the purpose of this calculation, we will assume it's Interest only repayment."
                  placement="right"
                >
                  <InfoOutlinedIcon
                    sx={{ marginLeft: 1, fontSize: "1rem", color: "grey" }}
                  />
                </Tooltip>
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "1.5rem",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderTopLeftRadius: "0rem",
                      borderBottomLeftRadius: "0rem",
                      // marginTop: 1,
                      borderRadius: 5,
                      boxShadow: 3,
                    },
                  }}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  name="interestType"
                  placeholder="Interest Type"
                  fullWidth
                  value={data.interestType}
                  inputProps={{
                    style: { fontSize: "13px" },
                  }}
                  onChange={(event) => {
                    setData({
                      ...data,
                      interestType: event.target.value,
                    });
                  }}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Capital Growth
              </Typography>
              <PercentageInput
                placeholder="%"
                sx={{
                  maxWidth: "350px",
                }}
                // style={{fontSize:'30px'}}
                value={data.capitalGrowth}
                onChange={(newValue) => {
                  const numericValue = parseFloat(newValue);
                  setData({
                    ...data,
                    capitalGrowth: isNaN(numericValue) ? null : numericValue,
                  });
                }}
              />
            </Stack>
            <Stack>
              {error.path === "capitalGrowth" && (
                <Typography
                  style={{
                    color: "purple",
                    fontSize: "0.75rem",
                    fontWeight: "600",
                    marginTop: "4px",
                  }}
                >
                  {error.message}
                </Typography>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Year Purchased
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "1.5rem",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderTopLeftRadius: "0rem",
                      borderBottomLeftRadius: "0rem",
                      // marginTop: 1,
                      borderRadius: 5,
                      boxShadow: 3,
                    },
                  }}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  name=" dateFrom"
                  placeholder=" Date From"
                  fullWidth
                  value={data.purchaseDate}
                  onChange={(event) => {
                    const value = event.target.value;
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                      setData({ ...data, purchaseDate: parsedValue });
                    } else {
                      console.error("Invalid number input");
                    }
                  }}
                  // onChange={(event) => {
                  //   const value = event.target.value;
                  //   setData({ ...data, purchaseDate: value });
                  // }}
                  inputProps={{
                    style: { fontSize: "13px" },
                  }}
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={2}>
            <Stack>
              <Typography
                sx={{
                  color: "#0f2e5a",
                  fontWeight: 600,
                  fontSize: "16px",
                }}
              >
                Investment Period
              </Typography>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "1.5rem",
                    },
                  }}
                  InputProps={{
                    sx: {
                      borderTopLeftRadius: "0rem",
                      borderBottomLeftRadius: "0rem",
                      // marginTop: 1,
                      borderRadius: 5,
                      boxShadow: 3,
                    },
                  }}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  type="text"
                  name="investmentPeriod"
                  placeholder="Investment Period"
                  fullWidth
                  value={data.investmentPeriod}
                  // onChange={(event) => {
                  //   const value = event.target.value;
                  //   setData({ ...data, investmentPeriod: value });
                  // }}

                  onChange={(event) => {
                    const value = event.target.value;
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                      setData({ ...data, investmentPeriod: parsedValue });
                    } else {
                      console.error("Invalid number input");
                    }
                  }}
                  inputProps={{
                    style: { fontSize: "13px" },
                  }}
                />
              </Stack>
              <Stack>
                {error.path === "investmentPeriod" && (
                  <Typography
                    style={{
                      color: "purple",
                      fontSize: "0.75rem",
                      fontWeight: "600",
                      marginTop: "4px",
                    }}
                  >
                    {error.message}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <Stack
              mb={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Button
                variant="contained"
                disableElevation
                onClick={() => calculateData()}
                sx={{
                  border: "1px solid lightgray",
                  width: "fit-content",

                  fontWeight: 600,
                  color: "#fff",
                  bgcolor: "#24003D !important",
                  border: "1px solid #24003D !important",
                  textTransform: "none",
                  borderRadius: "5px",
                  px: 3,
                  boxShadow:
                    "rgba(17, 17, 17, 0.04) 0px 2px 4px 0px, rgba(33, 33, 33, 0.08) 0px 8px 32px 0px",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              >
                Calculate
              </Button>
              <Button
                disableElevation
                variant="contained"
                onClick={() => {
                  setData({
                    propertyType: "",
                    initialInvestment: "",
                    loanAmount: "",
                    interestRate: "",
                    interestType: "Fixed",
                    capitalGrowth: "",
                    rentalGrowth: "",
                    weeklyRent: "",
                    annualDeductions: "",
                    depreciation: "",
                    purchaseDate: "",
                    investmentPeriod: "",
                  });
                  clearTable();
                }}
                sx={{
                  border: "1px solid lightgray",
                  width: "fit-content",
                  marginLeft: "1em",
                  fontWeight: 600,
                  letterSpacing: 0,
                  color: "#000",
                  bgcolor: "#fff !important",
                  textTransform: "none",
                  border: "2px solid #24003D !important",
                  borderRadius: "8px",
                }}
              >
                Clear All
              </Button>
            </Stack>
          </Grid>
        </Grid>

        {/* <Grid
          px={1}
          container
          justifyContent={"center"}
          spacing={{ xs: 0, md: 2 }}
          mt={{ xs: 8, md: 4 }}
        >
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  {summaryViewTableObjects.map((title, index) => (
                    <TableCell key={index} align="left">
                      <Stack
                        minWidth={"maxWidth"}
                        width={"100%"}
                        gap={3}
                        direction={"row"}
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Stack className="table-header">
                          <Typography
                            // width={"100px"}
                            align="center"
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                          >
                            {title?.value}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {tableTitlesIncome.map((item, index) => {
                    return (
                      <>
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {item}
                          </TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">- </TableCell>
                          <TableCell align="center"> -</TableCell>
                          <TableCell align="center"> -</TableCell>
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center"> -</TableCell>
                          <TableCell align="center">- </TableCell>
                          <TableCell align="center">- </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid> */}

        <Grid
          px={1}
          container
          justifyContent={"center"}
          spacing={{ xs: 0, md: 2 }}
          mt={{ xs: 8, md: 4 }}
        >
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  {investmentTableObjects.map((title, index) => (
                    <TableCell
                      sx={{ backgroundColor: "#24003D" }}
                      key={index}
                      align="left"
                    >
                      <Stack
                        minWidth={"maxWidth"}
                        width={"100%"}
                        gap={3}
                        direction={"row"}
                        alignItems={"center"}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Stack className="table-header">
                          <Typography
                            // width={"100px"}
                            align="center"
                            sx={{ fontWeight: "bold", color: "#fff" }}
                          >
                            {title?.value}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {selectedInvestment !== undefined &&
                    selectedInvestment.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#d6d6d6",
                          }}
                        >
                          {item.k}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ backgroundColor: "#d6d6d6" }}
                        >
                          {item.l}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.m)}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.n)}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.o)}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.p)}
                        </TableCell>
                        <TableCell align="center">
                          {item.q !== null && item.q !== undefined
                            ? `${item.q}%`
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {formatCurrency(item.r)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Home>
    </>
  );
};

export default LVRProjections;
