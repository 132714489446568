import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Bed from "../../../../assests/bed.svg";
import Bathroom from "../../../../assests/bathroom.svg";
import Garage from "../../../../assests/garage.svg";
import NoImagePlaceholder from "../../../../assests/noimage.jpg"; // Add this placeholder image
import { S3_FILE_STORAGE_URL } from "../../../../constants/portConstants";
import { capitalizeFirstLetter } from "../../../../helpers/functions";
import apiRequest from "../../../../services/auth";
import StarIcon from "@mui/icons-material/Star";
import { getFilteredCampaigns } from "../../../../store/Campaign/campaignActions";

const FavouriteListing = ({ data }) => {
  const location = useLocation();
  const params = useParams();
  const { isHide } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFavourite, setIsFavourite] = useState(false);
  const [viewType, setViewType] = useState("list");
  const [favorites, setFavorites] = useState({});
  const [search, setSearch] = useState({
    address: "",
    bedRoomMin: 0,
    bedRoomMax: 6,
    bathRoomMin: 0,
    bathRoomMax: 6,
    garageMin: 0,
    garageMax: 6,
    landAreaMin: "",
    landAreaMax: "",
    minPrice: "",
    maxPrice: "",
    isFavourite: true,
  });
 
  useEffect(() => {
    const initialFavorites = {};
    data.forEach((camp) => {
      initialFavorites[camp._id] = camp.isFavourite; 
    });
    setFavorites(initialFavorites);
  }, [data]);

  const handleFavoriteToggle = async (camp) => {
    const newFavoriteStatus = !favorites[camp._id]; 
    setFavorites((prev) => ({
      ...prev,
      [camp._id]: newFavoriteStatus, 
    }));

    try {
      const response = await apiRequest({
        url: `campaign/campaigns/favourite/${camp._id}`,
        method: "PUT",
        data: { isFavourite: newFavoriteStatus },
      });

      if (response?.success) {
        dispatch(getFilteredCampaigns(search));

      } else {
        console.error("Failed to update favorite status:", response);
        setFavorites((prev) => ({
          ...prev,
          [camp._id]: !newFavoriteStatus, 
        }));

      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
      setFavorites((prev) => ({
        ...prev,
        [camp._id]: !newFavoriteStatus, 
      }));
    }
  };

  const handleViewChange = () => {
    setViewType(viewType === "grid" ? "list" : "grid");
  };

  return (
    <Grid item xs={12} md={12} mt={2}>
      <Box
        sx={{
          bgcolor: "#fff",
          paddingBottom: 3,
          paddingTop: 3,
          paddingX: 4,
          borderRadius: 1,
          width: "100%",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "#000",
            fontWeight: 600,
            fontSize: "25px",
            mb: 3,
          }}
        >
          Favourite Listings ({ data.filter(camp => camp.isFavourite).length})
        </Typography>

        {/* Toggle Buttons */}
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            flexDirection: "row",
            gap: 1,
          }}
        >
          <IconButton
            color={viewType === "list" ? "primary" : "default"}
            onClick={handleViewChange}
          >
            <ViewListIcon />
          </IconButton>
          <IconButton
            color={viewType === "grid" ? "primary" : "default"}
            onClick={handleViewChange}
          >
            <GridViewIcon />
          </IconButton>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {data.length > 0 &&
                data.filter(camp => camp.isFavourite).map((camp, index) => (
                  <Grid key={index} item xs={viewType === "grid" ? 4 : 12}>
                    <Box
                      sx={{ position: "relative", mb: 4 }}
                      // onClick={() =>
                      //   navigate(`/property-details/${camp._id}`)
                      // }
                    >
                      <Stack
                        sx={{
                          backgroundColor: "#fff",
                          boxShadow: "0px 4px 5px 2px rgba(0, 0, 0, 0.1)",
                          borderRadius: 2,
                          borderColor: "gray",
                          overflow: "hidden",
                        }}
                        // onClick={() =>
                        //   navigate(`/property-details/${camp._id}`)
                        // }
                      >
                        <Stack
                          sx={{
                            backgroundColor: "#24003d",
                            height: viewType === "grid" ? 40 : 70,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            px={2}
                            fontSize={20}
                            color={"#fff"}
                            fontWeight={"bold"}
                          >
                            {capitalizeFirstLetter(camp.description.title)}
                          </Typography>
                        </Stack>

                        <Grid
                          container
                          spacing={2}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={viewType === "list" ? 6 : 12}>
                            <Stack>
                              {/* <img
                              style={{
                                width: "100%",
                                height: viewType === "grid" ? 170 : 300,
                                objectFit: "cover",
                                display: "block",
                              }}
                              src={
                                camp.media?.photo?.file
                                  ? `${S3_FILE_STORAGE_URL}/${camp.media.photo.file}`
                                  : NoImagePlaceholder
                              }
                              alt={""}
                            /> */}
                              <div
                               onClick={() =>
                                navigate(`/property-details/${camp._id}`)
                              }>
                                {camp?.media?.photo?.file?.length > 0 ? (
                                  <Carousel
                                    showArrows={true}
                                    showThumbs={false}
                                    infiniteLoop={true}
                                    autoPlay={true}
                                    dynamicHeight={true}
                                  >
                                    {camp?.media?.photo?.file?.map(
                                      (image, index) => (
                                        <div key={index}>
                                          <img
                                            src={`${S3_FILE_STORAGE_URL}/${image}`}
                                            alt={`Slide ${index}`}
                                            style={{
                                              width: "100%",
                                              height:
                                                viewType === "grid"
                                                  ? "200px"
                                                  : "300px",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </Carousel>
                                ) : (
                                  <img
                                    src={NoImagePlaceholder}
                                    alt="No available images"
                                    style={{
                                      width: "100%",
                                      height:
                                        viewType === "grid" ? "200px" : "300px",
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                              </div>
                            </Stack>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={2}>
                              {viewType === "list" && (
                                <>
                                  <Grid item xs={4}>
                                    <Stack direction={"row"} spacing={2} mb={2}>
                                      <Stack
                                        direction={"row"}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        spacing={0.5}
                                      >
                                        <Typography
                                          sx={{ fontWeight: 600, fontSize: 14 }}
                                        >
                                          {camp?.details?.bathRoom}
                                        </Typography>
                                        <img
                                          style={{
                                            width: 15,
                                            height: 15,
                                            display: "block",
                                            filter: "brightness(0) invert(0)",
                                          }}
                                          src={Bathroom}
                                          alt=""
                                        />
                                      </Stack>
                                      <Stack
                                        direction={"row"}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        spacing={0.5}
                                      >
                                        <Typography
                                          sx={{ fontWeight: 600, fontSize: 14 }}
                                        >
                                          {camp?.details?.bedRoom}
                                        </Typography>
                                        <img
                                          style={{
                                            width: 15,
                                            height: 15,
                                            display: "block",
                                            filter: "brightness(0) invert(0)",
                                          }}
                                          src={Bed}
                                          alt=""
                                        />
                                      </Stack>
                                      <Stack
                                        direction={"row"}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        spacing={0.5}
                                      >
                                        <Typography
                                          sx={{ fontWeight: 600, fontSize: 14 }}
                                        >
                                          {camp?.details?.garage}
                                        </Typography>
                                        <img
                                          style={{
                                            width: 15,
                                            height: 15,
                                            display: "block",
                                            filter: "brightness(0) invert(0)",
                                          }}
                                          src={Garage}
                                          alt=""
                                        />
                                      </Stack>
                                    </Stack>
                                    <Stack spacing={2}>
                                      <Stack direction={"row"}>
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            color: "#0f2e5a",
                                          }}
                                        >
                                          House Area: {camp?.details?.houseArea}{" "}
                                          m²
                                        </Typography>
                                      </Stack>
                                      <Stack direction={"row"}>
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            color: "#0f2e5a",
                                          }}
                                        >
                                          Land Area: {camp?.details?.landArea}{" "}
                                          m²
                                        </Typography>
                                      </Stack>
                                      <Stack direction={"row"}>
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            color: "#0f2e5a",
                                          }}
                                        >
                                          LivingArea:{" "}
                                          {camp?.details?.livingArea}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "start",
                                      }}
                                    >
                                      <Stack spacing={1}>
                                        <Typography
                                          fontWeight={700}
                                          sx={{ fontSize: 18 }}
                                        >
                                          {camp?.userData?.firstName}{" "}
                                          {camp?.userData?.lastName}
                                        </Typography>
                                        <Stack spacing={1} direction={"row"}>
                                          <Typography
                                            fontWeight={700}
                                            sx={{ fontSize: 14 }}
                                          >
                                            Mobile No :
                                          </Typography>
                                          <Typography
                                            fontWeight={600}
                                            sx={{ fontSize: 14 }}
                                          >
                                            {camp?.userData?.mobileNumber}
                                          </Typography>
                                        </Stack>
                                        <Stack spacing={1} direction={"row"}>
                                          <Typography
                                            fontWeight={700}
                                            sx={{ fontSize: 14 }}
                                          >
                                            Email ID
                                          </Typography>
                                          <Typography
                                            fontWeight={600}
                                            sx={{ fontSize: 14 }}
                                          >
                                            : {camp?.userData?.email}
                                          </Typography>
                                        </Stack>

                                        <Stack spacing={1} direction={"row"}>
                                          <Typography
                                            fontWeight={700}
                                            sx={{ fontSize: 14 }}
                                          >
                                            Name :
                                          </Typography>
                                          <Typography
                                            fontWeight={600}
                                            sx={{ fontSize: 14 }}
                                          >
                                            {
                                              camp?.userData.realEstate
                                                ?.firstName
                                            }
                                            {
                                              camp?.userData?.realEstate
                                                ?.lastName
                                            }
                                          </Typography>
                                        </Stack>

                                        <Stack>
                                          <Typography
                                            fontWeight={700}
                                            sx={{ fontSize: 14 }}
                                          >
                                            Address :
                                          </Typography>
                                          <Typography
                                            fontWeight={600}
                                            sx={{ fontSize: 14 }}
                                          >
                                            {
                                              camp?.userData?.realEstate
                                                ?.address?.fullAddress
                                            }
                                          </Typography>
                                        </Stack>
                                        <Stack spacing={1} direction={"row"}>
                                          <Typography
                                            fontWeight={700}
                                            sx={{ fontSize: 14 }}
                                          >
                                            Lic :
                                          </Typography>
                                          <Typography
                                            fontWeight={600}
                                            sx={{ fontSize: 14 }}
                                          >
                                            {
                                              camp?.userData?.realEstate
                                                ?.licenseNumber
                                            }
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    </Stack>
                                  </Grid>

                                  {viewType === "grid" && (
                                    <Grid item xs={12}>
                                      <Stack spacing={2}>
                                        <Stack
                                          spacing={0.5}
                                          direction={"row"}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: 14,
                                            }}
                                          >
                                            {camp?.details?.bathRoom}
                                          </Typography>
                                          <img
                                            style={{
                                              width: 15,
                                              height: 15,
                                              display: "block",
                                              filter: "brightness(0) invert(0)",
                                            }}
                                            src={Bathroom}
                                            alt=""
                                          />
                                        </Stack>
                                        <Stack direction={"row"} spacing={0.5}>
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: 14,
                                            }}
                                          >
                                            {camp?.details?.bedRoom}
                                          </Typography>
                                          <img
                                            style={{
                                              width: 15,
                                              height: 15,
                                              display: "block",
                                              filter: "brightness(0) invert(0)",
                                            }}
                                            src={Bed}
                                            alt=""
                                          />
                                        </Stack>
                                        <Stack
                                          direction={"row"}
                                          sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                          spacing={0.5}
                                        >
                                          <Typography
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: 14,
                                            }}
                                          >
                                            {camp?.details?.garage}
                                          </Typography>
                                          <img
                                            style={{
                                              width: 15,
                                              height: 15,
                                              display: "block",
                                              filter: "brightness(0) invert(0)",
                                            }}
                                            src={Garage}
                                            alt=""
                                          />
                                        </Stack>
                                      </Stack>
                                    </Grid>
                                  )}
                                  <Grid item xs={12}>
                                    <Stack
                                      width={"100%"}
                                      direction={"row"}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        fontWeight={400}
                                        sx={{
                                          fontSize: 18,
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          color: "grey",
                                        }}
                                      >
                                        {camp?.description?.description}
                                      </Typography>

                                      <div
                                        onClick={() =>
                                          handleFavoriteToggle(camp)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {favorites[camp._id] ? (
                                          <StarIcon
                                            sx={{
                                              fontSize: 30,
                                              mr: 3,
                                              color: "gold",
                                            }}
                                          />
                                        ) : (
                                          <StarBorderIcon
                                            sx={{ fontSize: 30, mr: 3 }}
                                          />
                                        )}
                                      </div>
                                    </Stack>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* </div>
                        ))}
                      </Carousel> */}

                        <Stack spacing={2} p={viewType === "grid" ? 2 : 0}>
                          {viewType === "grid" && (
                            <>
                              <Stack
                                direction={"row"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "start",
                                }}
                              >
                                <Typography
                                  fontWeight={400}
                                  sx={{
                                    fontSize: 18,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    color: "grey",
                                  }}
                                >
                                  {camp?.description?.description}
                                </Typography>

                                <div
                                  onClick={() => handleFavoriteToggle(camp)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {favorites[camp._id] ? (
                                    <StarIcon
                                      sx={{
                                        fontSize: 30,
                                        mr: 3,
                                        color: "gold",
                                      }}
                                    />
                                  ) : (
                                    <StarBorderIcon
                                      sx={{ fontSize: 30, mr: 3 }}
                                    />
                                  )}
                                </div>
                              </Stack>

                              <Stack direction={"row"} spacing={2}>
                                <>
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      filter: "brightness(0) invert(0)",
                                    }}
                                    spacing={0.5}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 600, fontSize: 14 }}
                                    >
                                      {camp?.details?.bathRoom}
                                    </Typography>
                                    <img
                                      style={{
                                        width: 15,
                                        height: 15,
                                        display: "block",
                                      }}
                                      src={Bathroom}
                                      alt=""
                                    />
                                  </Stack>
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    spacing={0.5}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 600, fontSize: 14 }}
                                    >
                                      {camp?.details?.bedRoom}
                                    </Typography>
                                    <img
                                      style={{
                                        width: 15,
                                        height: 15,
                                        display: "block",
                                        filter: "brightness(0) invert(0)",
                                      }}
                                      src={Bed}
                                      alt=""
                                    />
                                  </Stack>
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    spacing={0.5}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 600, fontSize: 14 }}
                                    >
                                      {camp?.details?.garage}
                                    </Typography>
                                    <img
                                      style={{
                                        width: 15,
                                        height: 15,
                                        display: "block",
                                        filter: "brightness(0) invert(0)",
                                      }}
                                      src={Garage}
                                      alt=""
                                    />
                                  </Stack>
                                  <Divider orientation="vertical" flexItem />
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    spacing={0.5}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: 14,
                                        color: "#0f2e5a",
                                      }}
                                    >
                                      Living Area: {camp?.details?.livingArea}
                                    </Typography>
                                  </Stack>
                                </>
                              </Stack>
                              <Stack direction={"row"} spacing={2}>
                                <>
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    spacing={0.5}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: 14,
                                        color: "#0f2e5a",
                                      }}
                                    >
                                      House Area: {camp?.details?.houseArea} m²
                                    </Typography>
                                  </Stack>
                                  <Divider orientation="vertical" flexItem />
                                  <Stack
                                    direction={"row"}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    spacing={0.5}
                                  >
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: 14,
                                        color: "#0f2e5a",
                                      }}
                                    >
                                      Land Area: {camp?.details?.landArea} m²
                                    </Typography>
                                  </Stack>
                                </>
                              </Stack>
                            </>
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default FavouriteListing;
