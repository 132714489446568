import "./index.css";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import Home from "../../../components/SidebarWithTopBar";
import TownSquareSelection from "./TownSquareSelection";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cursor } from "mongoose";

const TownSquare = () => {
  const [activeTab, setActiveTab] = useState("None");
  const navigate = useNavigate();

  return (
    <Home>
      <Grid container mt={5} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="left" px={6}>
            One stop community place for new deals !!!
          </Typography>
        </Grid>

        {activeTab === "None" && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '70vh' }} // Full viewport height
          >
            <Grid item xs={12} mt={5}>
              <Grid container spacing={2} justifyContent="space-evenly">
                <Grid item>
                  <Box
                    onClick={() => navigate("/investment-property")}
                    sx={{
                      bgcolor: "#fff",
                      color: "#24003D",
                      width: 400,
                      height: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      position: "relative",
                      borderRadius: 4,
                      cursor: "pointer",
                      border: "2px solid #24003D", // Add border color
                      transition:
                        "transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease", // Transition for smooth color change
                      "&:hover": {
                        transform: "scale(1)", // Slight scaling on hover
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Enhanced shadow on hover
                        color: "white", // Change text color on hover
                        backgroundColor: "#24003D", // Change background color on hover
                        "& .MuiTypography-root": {
                          // Target Typography child
                          color: "white", // Change Typography color on hover
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#24003D", // Initial color
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Investment Properties
                    </Typography>
                  </Box>
                </Grid>

                <Grid item>
                  <Box
                    sx={{
                      bgcolor: "#fff",
                      color: "#24003D",
                      width: 400,
                      height: 150,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      position: "relative",
                      borderRadius: 4,
                      cursor: "pointer",
                      transition:
                        "transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease", // Transition for smooth color change
                      border: "2px solid #24003D", // Add border color
                      "&:hover": {
                        transform: "scale(1)", // Slight scaling on hover
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Enhanced shadow on hover
                        color: "white", // Change text color on hover
                        backgroundColor: "#24003D", // Change background color on hover
                        "& .MuiTypography-root": {
                          // Target Typography child
                          color: "white", // Change Typography color on hover
                        },
                      },
                    }}
                    onClick={() => navigate("/home-loan")}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#24003d",
                        fontSize: 20,
                        fontWeight: "bold",
                        Cursor: "pointer",
                      }}
                    >
                      Home Loans
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Home>
  );
};

export default TownSquare;
