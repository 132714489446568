import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Pasted from "../../../assests/Calculater Theme.jpg";
import Home from "../../../assests/Calculator.jpg";
import bgImg from "../../../assests/Maths.jpg";
import Header from "../../../components/Header";
import HomeFooter from "../HomeFooter";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 150,
    border: "1px solid #000",
    borderRadius: 0,
  },
  verticalHeader: {
    backgroundColor: "lightgray",
    color: "#000",
    fontWeight: "bold",
    width: "20%",
    borderBottom: "1px solid #000", // Border between data cells
    borderRight: "1px solid #000", // Border between header cells
    alignItems: "start",
  },
  normalCell: {
    // textAlign: 'center',
    borderBottom: "1px solid #000", // Border between data cells
  },
});

const CookiePolicy = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const email = "info@investpulse.com.au";

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container mb={10}>
          <Grid item xs={12} md={12}>
            <Stack mt={3} alignItems={"center"} justifyContent={"center"}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                COOKIES POLICY
              </Typography>
            </Stack>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                1.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  INTRODUCTION
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    {" "}
                    This document sets out the Cookies Policy of Gritpro Pty Ltd
                    ACN 613 057 097 trading as ‘Investpulse’(referred to in this
                    cookies policy as
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      ‘we’, ‘us’,
                    </span>{" "}
                    or{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      ‘our’
                    </span>{" "}
                    )
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    This Cookies Policy applies when you use our website
                    accessible at{" "}
                    <a
                      style={{
                        color: "#33c7c1",
                      }}
                      href="https://www.investpulse.com.au/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.investpulse.com.au
                    </a>{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      (“Website”)
                    </span>
                    and describes the types of cookies we use on our Website,
                    how we use them, and how you can control them.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    We may update this Cookies Policy from time to time by
                    posting an updated copy on our website and we encourage you
                    to check our website regularly to ensure that you are aware
                    of our most current Cookies Policy.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                2.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  TYPES OF COOKIES WE USE{" "}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  A cookie is a small file that’s stored on your computer or
                  device when you visit a website that uses cookies. We may use
                  several different cookies on our Website, for the purposes of
                  website functionality, performance, advertising, and social
                  media or content cookies. Cookies enhance your experience on
                  our Website, as it allows us to recognise you, remember your
                  details and preferences (for example, your log-in details),
                  and provide us with information on when you’ve visited and how
                  you’ve interacted with our Website.{" "}
                </Typography>
                <Typography>
                  The below table sets out the type of cookies we may collect on
                  our Website.
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      //   aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.verticalHeader}>
                            <Typography fontWeight={"bold"}>
                              Strictly Necessary Cookies
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                              Certain cookies we use are essential for the
                              proper functioning of our Website, without which
                              our Website won’t work or certain features won’t
                              be accessible to you. For example, we may need to
                              remember data you’ve inputted from one page to the
                              next in a single session.
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.verticalHeader}>
                            <Typography fontWeight={"bold"}>
                              Performance Cookies
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                              Performance cookies collect information about your
                              use of the Website to help enhance the services we
                              provide to you. We collect information about how
                              you interact with the Website, including the pages
                              you visit and the frequency of your visits. This
                              information helps us identify patterns of usage on
                              the site, collect analytics data, identify issues
                              you may have had on the Website, make changes to
                              enhance your browsing experience, and analyse if
                              our marketing is effective and relevant to you.
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {/* 3rd */}
                        <TableRow>
                          <TableCell className={classes.verticalHeader}>
                            <Typography fontWeight={"bold"}>
                              Functional Cookies{" "}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Stack spacing={1}>
                              <Typography>
                                We use functional cookies to improve your
                                experience on our Website and make things more
                                convenient for you. These cookies personalise
                                your experience on our Website based on your
                                preferences, by remembering your details such as
                                your login details or region.{" "}
                              </Typography>
                              <Typography>
                                Security cookies are a type of functional
                                cookie, which assist with website and user
                                account security. Load balancing session cookies
                                are used for the duration of the session to
                                distribute user requests across multiple servers
                                to optimize website speed and capacity. We may
                                also use user interface customization persistent
                                cookies to store a user’s preferred version of
                                our Website, such as font and language
                                preferences.{" "}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.verticalHeader}>
                            <Typography fontWeight={"bold"}>
                              Advertising cookies{" "}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                              Advertising cookies are used on our Website to
                              provide you with targeted marketing materials in
                              accordance with your interests and preferences.
                              These cookies remember that you visited our
                              Website, and we may provide this information to
                              third-parties. These cookies usually cannot
                              personally identify you, so your anonymity is
                              typically secured. These cookies ensure that
                              advertisements displayed to you are things that
                              may be of interest to you.{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={classes.verticalHeader}>
                            <Typography fontWeight={"bold"}>
                              Content cookies{" "}
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.normalCell}>
                            <Typography>
                              Content cookies are placed by many social media
                              plugins (like plugins that allow you to share
                              content on Facebook), and other tools to enhance
                              the content displayed on a website (for example,
                              services that allow the playing of video files).
                              We integrate these plugins into our Website to
                              improve usability and customer experience. Some of
                              these third party services may place cookies that
                              are also used for the purposes of behavioural
                              advertising or market analysis.{" "}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                3.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  HOW LONG WILL COOKIES REMAIN ON MY DEVICE?
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  The amount of time that a cookie remains on your computer or
                  device depends on the type of cookie – cookies are either
                  “persistent” or “session” cookies. Persistent cookies last
                  until they expire or are deleted, so they may remain on your
                  device for as little as 10 minutes to several years. Session
                  cookies last until you stop browsing, so just for the relevant
                  session.{" "}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                4.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  HOW DO THIRD PARTIES USE COOKIES ON THE WEBSITE?
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography mt={2}>
                  We may use third party analytics cookies to collect
                  information about your interaction with our Website. We also
                  may use Google Analytics and other third-party analytics
                  providers to help process data. To find out more, see{" "}
                  <a
                    //   style={{
                    //     color: "#33c7c1",
                    //   }}
                    href="https://policies.google.com/technologies/partner-sites"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    How Google uses data when you use our partners’ sites or
                    apps.{" "}
                  </a>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                5.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  HOW DO I CONTROL COOKIES?
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography>(a)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    {" "}
                    Usually, you can control and manage cookies through your
                    browser. You can control whether or not your browser accepts
                    cookies, how to filter and manage cookies, and how to delete
                    cookies at the end of a session.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(b)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    If you remove or block cookies, this may negatively impact
                    your experience of our Website and you may not be able to
                    access all parts of our Website.
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography>(c)</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography>
                    Many third party advertising services allow you to opt out
                    of their tracking systems, by giving you the opportunity to
                    opt out by way of a pop-up before downloading cookies to
                    your device.{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={3}>
            <Grid item xs={1}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                6.
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <Stack>
                {" "}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  CONTACT US
                </Typography>
              </Stack>
              <Stack spacing={1} mt={1}>
                <Typography mt={3}>
                  For further information about our Cookies Policy or practices,
                  please contact us using the details set out below:
                </Typography>
              </Stack>
              <Grid container mt={1} spacing={1}>
                <Grid item xs={1}>
                  <Typography fontWeight={"bold"}>Name:</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography fontWeight={"bold"}>Customer Support </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography fontWeight={"bold"}>Email:</Typography>
                </Grid>
                <Grid item xs={11}>
                  <Typography color={"#33c7c1"}>
                    <a
                      style={{
                        color: "#33c7c1",
                      }}
                      href={`mailto:${email}`}
                    >
                      {email}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Stack spacing={1} mt={1}>
                <Typography>
                  Our Cookies Policy was last updated on 3 April 2024.
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <HomeFooter />
    </>
  );
};

export default CookiePolicy;
