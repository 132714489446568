const socketEvents = {
  WS_CONNECT: "socket/wsConnect",
  WS_DISCONNECT: "socket/wsDisconnect",
  SEND_MESSAGE: "socket/wsSendMessage",
  NEW_NOTIFICATION: "NEW_NOTIFICATION",
  RECEIVE_NOTIFICATION: "RECEIVE_NOTIFICATION",
  UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
  JOIN: "JOIN",
};

export default socketEvents;
